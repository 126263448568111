import styled from 'styled-components'

export const AddProductWrapper = styled.div`
  gap: 20px;
  padding: 5%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: white;
  
  &:nth-child(1n) {
    width: 15%;
  }
  
  & .MuiFormControl-fullWidth {
    margin-top: 10px;
  }
`

export const AddProductWrapperTitle = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`
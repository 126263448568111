import React, {useEffect, useState} from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {lpack} from "../../components/Language/language";
import Cookies from 'js-cookie';
import axios from "axios";
import {urlPath} from "../../config/config";
import PagesTable from "../../components/PagesTable/PagesTable";

let hash

if (Cookies.get('user')) {
    hash = Cookies.get('user')
} else if (sessionStorage.getItem('user')) {
    hash = sessionStorage.getItem('user')
} else {
    sessionStorage.setItem('user', window.location.hash.slice(1, 33))
}

function Pages() {
    const [pagesData, setPagesData] = useState([])

    const getPages = () => {
        axios.post(urlPath + 'pages', {}, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then((response) => {
            const responseData = response.data
            setPagesData(responseData)
        })
    }

    useEffect(() => {
        getPages()
    }, [])

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="danger">
                            <h4>{lpack.pages}</h4>
                        </CardHeader>
                        <CardBody>
                            <PagesTable
                                data={pagesData}
                                getCategories={getPages}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default Pages
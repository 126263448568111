import React, {useEffect, useState} from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {lpack} from "../../components/Language/language";
import Cookies from 'js-cookie';
import axios from "axios";
import {colors, colorsUrl, urlPath} from "../../config/config";
import ColorsTable from "../../components/ColorsTable/ColorsTable";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {ChromePicker, CirclePicker} from "react-color";
import {
    ColorsTableColorFilter, ColorsTableFilter,
    ColorsTableFilterByBiggerOrSmallerValue,
    ColorsTableFilterColumn, ColorsWrapper
} from "./Colors.styled";

let hash

if (Cookies.get('user')) {
    hash = Cookies.get('user')
} else if (sessionStorage.getItem('user')) {
    hash = sessionStorage.getItem('user')
} else {
    sessionStorage.setItem('user', window.location.hash.slice(1, 33))
}

function Colors() {
    const [color, setColor] = useState('#FFFFFF')
    const [colorName, setColorName] = useState('')
    const [colorsData, setColorsData] = useState([])

    useEffect(() => {
        setColorName(
            colors.filter(item => item.hex.toLowerCase() === color).length !== 0
                ? colors.filter(item => item.hex.toLowerCase() === color)[0].name
                : colorName
        )
    }, [color])

    const getColors = () => {
        axios.post(urlPath + 'colors', {}, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then((response) => {
            const responseData = response.data
            setColorsData(responseData)
        })
    }

    useEffect(() => {
        getColors()
    }, [])

    const addColorHandler = async () => {
        const data = {
            name: {
                default: colorName,
                en: colorName
            },
            code: color,
        }
        await axios.post(colorsUrl, data, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        })
        getColors()
    }

    return (
        <ColorsWrapper>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="danger">
                            <h4>{lpack.colors}</h4>
                        </CardHeader>
                        <CardBody>
                            <ColorsTableFilterByBiggerOrSmallerValue>
                                <ColorsTableFilterColumn>
                                    <ColorsTableFilter>
                                        <ChromePicker
                                            color={color}
                                            onChange={(color) => setColor(color.hex)}
                                        />
                                        <CirclePicker
                                            color={color && color}
                                            colors={colors.map(color => color.hex)}
                                            circleSize={40}
                                            onChange={(color) => setColor(color.hex)}
                                        />
                                    </ColorsTableFilter>
                                    <ColorsTableColorFilter>
                                        <TextField
                                            id="outlined-basic"
                                            label="Color name"
                                            variant="outlined"
                                            color="secondary"
                                            value={
                                                colors.filter(item => item.hex.toLowerCase() === color).length !== 0
                                                    ? colors.filter(item => item.hex.toLowerCase() === color)[0].name
                                                    : colorName
                                            }
                                            className={'color_filter'}
                                            onChange={(e) => setColorName(e.target.value)}
                                        />
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            className={'color_filter-button'}
                                            onClick={addColorHandler}
                                        >
                                            Add color
                                        </Button>
                                    </ColorsTableColorFilter>
                                </ColorsTableFilterColumn>
                            </ColorsTableFilterByBiggerOrSmallerValue>
                            <ColorsTable
                                data={colorsData}
                                getColors={getColors}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </ColorsWrapper>
    );
}

export default Colors
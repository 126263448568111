import React, {Component, createRef, useRef} from "react";
import axios from "axios";
import MaterialTable from "material-table";
import {lpack} from "../Language/language";
import Cookies from 'js-cookie';
import {galleryDelete, galleryUrl, getImage, productsAdd, productsDelete, productsUrl} from "../../config/config";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styled from 'styled-components'
import TextField from "@material-ui/core/TextField";
import UploadImage from "../UploadImage/UploadImage";
import Remove from '../../assets/img/removeImage.svg'
import CustomSelect from "../CustomSelect/CustomSelect";

const CustomTextField = styled(TextField)`
  width: ${p => p.width ? `${p.width}px` : '150px'};
`

const RowField = styled.div`
  display: flex;
  align-items: center;
`

const RemoveImage = styled.img`
  width: 24px;
  height: 24px;
  padding: 16px;
  cursor: pointer;
  border-radius: 50%;
  margin: 0 10px 5px 16px;
  background-color: white;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
`

const TableWrapper = styled.div`
  display: flex;
  
  & .MuiPaper-elevation2 {
    width: 100%;
  }

  & .Component-horizontalScrollContainer-119 {
    overflow-x: unset !important;
  }

  & .Component-horizontalScrollContainer-119 div div {
    overflow-y: unset !important;
    
    & div table {
      display: flex;
      flex-direction: column;
    }
  }
`

let hash

if (Cookies.get('user')) {
    hash = Cookies.get('user')
} else if (sessionStorage.getItem('user')) {
    hash = sessionStorage.getItem('user')
}

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 1);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page === 1 ? page : page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            {/*<IconButton*/}
            {/*    onClick={handleFirstPageButtonClick}*/}
            {/*    // disabled={page === 1}*/}
            {/*    aria-label="first page"*/}
            {/*>*/}
            {/*    {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}*/}
            {/*</IconButton>*/}
            <IconButton
                onClick={handleBackButtonClick}
                // disabled={page === 1}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton>
                {page}-{count}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            {/*<IconButton*/}
            {/*    onClick={handleLastPageButtonClick}*/}
            {/*    // disabled={page >= Math.ceil(count / rowsPerPage) - 1}*/}
            {/*    aria-label="last page"*/}
            {/*>*/}
            {/*    {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}*/}
            {/*</IconButton>*/}
        </div>
    );
}

export default class ProductsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {title: lpack.staffId, field: 'id', editable: 'never'},
                {
                    title: lpack.staffNickName,
                    field: 'name',
                    validate: rowData => rowData.name === '' ? 'Name cannot be empty' : ''
                },
            ],
            data: [],
            isMounted: false,
            hash: hash,
            currentPage: 1,
            editProductData: [],
            remoteData: [],
            fileMimeType: '',
            fileName: '',
            fileContent: '',
        }
    }

    addData = async (newData, url) => {
        const {data} = this.state
        const d = Object.keys(data.headers).map(key => {
            if (key !== 'id') {
                return key.includes('_id')
                    ? {[key]: 0}
                    : {
                        [key]: newData[key]
                            ? {
                                default: newData[key],
                                en: newData[key]
                            } : 0
                    }
            }
        })
        await axios.post(url, Object.assign({}, ...d), {
            headers: {
                Authorization: "Bearer " + this.state.hash,
            },
        })
        this.props.getData()
    }

    updateData = async (newData, url) => {
        const {editProductData, columns, fileName, fileMimeType, fileContent} = this.state
        const {remoteData} = this.props
        const editedData = await columns.map(key => {
            if (editProductData.filter(item => item.field === key.field).length !== 0) {
                return {
                    [key.field]: !key.field.includes('_id')
                        ? new RegExp(/^[0-9]*$/).test(newData[key.field])
                            ? Number(editProductData.filter(item => item.field === key.field)[0].name)
                            : {
                                en: editProductData.filter(item => item.field === key.field)[0].name,
                                default: editProductData.filter(item => item.field === key.field)[0].name,
                            }
                        : Number(editProductData.filter(item => item.field === key.field)[0].value.id) || 0
                }
            } else {
                const tableData = remoteData.filter(i => i.config.url.includes(key.field.slice(0, -3)))

                return {
                    [key.field]: editProductData.filter(item => item.field === key.field).length !== 0
                        ? new RegExp(/^[0-9]*$/).test(newData[key.field])
                            ? Number(editProductData.filter(item => item.field === key.field)[0].value)
                            : !key.field.includes('_id')
                                ? {
                                    en: editProductData.filter(item => item.field === key.field)[0].name,
                                    default: editProductData.filter(item => item.field === key.field)[0].name,
                                }
                                : typeof newData[key.field] === 'number'
                                    ? Number(editProductData.filter(item => item.field === key.field)[0].value.id)
                                    : 0
                        : new RegExp(/^[0-9]*$/).test(newData[key.field])
                            ? Number(newData[key.field])
                            : !key.field.includes('_id')
                                ? {
                                    en: newData[key.field],
                                    default: newData[key.field],
                                }
                                : tableData[0] !== undefined && newData[key.field] !== undefined
                                    ? tableData[0].data.data
                                        .filter(i => typeof i.name === 'object'
                                            ? i.name.default === newData[key.field]
                                            : i.name === newData[key.field])[0].id
                                    : 0
                }
            }
        })
        await axios.post(url, Object.assign({}, ...editedData), {
            headers: {
                Authorization: "Bearer " + this.state.hash,
            },
        }).then((res) => {
            const responseData = res.data.info
            const imageData = {
                productId: responseData.insert_id,
                files: [{
                    id: Math.random(),
                    name: fileName,
                    mimetype: fileMimeType,
                    content: fileContent,
                }]
            }
            this.setState({
                editProductData: [],
            })
            if (fileName !== '') {
                axios.post(galleryUrl, imageData, {
                    headers: {
                        Authorization: "Bearer " + hash,
                    },
                })
                this.setState({
                    fileName: '',
                    fileMimeType: '',
                    fileContent: ''
                })
            }
        })
        this.props.getData()
    }

    deleteData = async (oldData, url) => {
        await axios.post(`${url}/${oldData.id}`, {}, {
            headers: {
                Authorization: "Bearer " + this.state.hash,
            },
        })
        this.props.getData(oldData.id)
    }

    handleChangePage = (page) => {
        const data = {
            pagination: {
                page: page,
                limit: 25
            }
        }
        axios.post(productsUrl, data, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then(response => {
            const data = response.data
            this.setState({
                data: data
            })

            if (data.status === 'NOAUTH') {
                // remove()
            }
        })
    }

    editProductDataHandler(field, e) {
        const {editProductData} = this.state
        const fieldIdentity = editProductData.filter(e => e.field === field).length !== 0
        if (fieldIdentity) {
            this.setState({
                editProductData: editProductData.map(item =>
                    item.field === field
                        ? {
                            field: field,
                            name: e.target.value
                        }
                        : item
                )
            })
        } else {
            this.setState({
                editProductData: [
                    ...this.state.editProductData,
                    {
                        field: field,
                        name: e.target.value
                    }
                ]
            })
        }
    }

    componentWillUnmount() {
        this.setState({
            isMounted: false
        })
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextState.editProductData.length !== 0 || nextState.fileName !== '') {
            return false
        }
        return true
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.remoteData !== this.props.remoteData) {
            this.setState({
                editProductData: [],
                data: this.props.data
            })
        }
        if (
            prevState.editProductData !== this.state.editProductData ||
            prevState.data !== this.state.data
        ) {
            const {data} = this.state

            let parsedHeader = data.headers
            if (parsedHeader !== undefined) {
                parsedHeader = Object.values(parsedHeader).map(item => {
                    if (item.name.includes('gallery')) {
                        return {
                            title: item.sname.default,
                            field: item.name,
                            render: rowData => <img
                                style={{width: '7vw'}}
                                src={getImage + rowData.gallery_id}
                                alt="Product photo"
                            />
                        }
                    } else {
                        return {
                            title: item.sname.default,
                            field: item.name,
                        }
                    }
                })

                if (parsedHeader.length !== this.state.columns.length) {
                    this.setState({
                        columns: parsedHeader
                    })
                }
            }
        }
    }

    addImageToProducts = (name, type, file) => {
        this.setState({
            fileName: name,
            fileMimeType: type,
            fileContent: file
        }, () => {
            document.querySelectorAll('.MuiTableRow-root').forEach(row => {
                if (row.getAttribute('mode') === 'update') {
                    row.lastChild.firstChild.firstChild.firstChild.firstChild.innerHTML = name.length > 12
                        ? name.slice(0, 12) + '...'
                        : name.slice(0, 12)
                }
            })
        })
    }

    removeImageHandler = (image) => {
        axios.post(`${galleryDelete}/${image.id}`, {}, {
            headers: {
                Authorization: "Bearer " + this.state.hash,
            },
        }).then(() => {
            document.querySelectorAll('.MuiTableRow-root').forEach(row => {
                if (row.getAttribute('mode') === 'update') {
                    row.lastChild.firstChild.firstChild.firstChild.firstChild.innerHTML = "Add image"
                }
            })
        })
    }

    selectOnChangeHandler = (newValue, field, value) => {
        if (newValue) {
            this.setState({
                editProductData: [
                    ...this.state.editProductData,
                    {
                        field: field,
                        value: value,
                    }
                ]
            })
        } else {
            this.setState({
                editProductData: [
                    ...this.state.editProductData.filter(el => el.field !== field),
                    {
                        field: field,
                        value: value,
                    }
                ]
            })
        }
    }

    render() {
        const {columns, data, fileName, fileMimeType, fileContent, currentPage, editProductData} = this.state
        const {remoteData} = this.props

        const filteredData = data.data && data.data.map((item, i) => {
            const headers = Object.keys(item).map(header => {
                if (header.includes('_id')) {
                    // const headerData = remoteData.filter(data => data.config.url.includes(header.slice(0, -3)))[0]
                    // const dataItem = {
                    //     [header]: typeof headerData?.data?.data?.name === 'string'
                    //         ? headerData?.data?.data?.name
                    //         : typeof headerData?.data?.data === 'undefined'
                    //             ? headerData?.data?.headers?.fileName?.sname?.default
                    //             : Array.isArray(headerData?.data?.data)
                    //                 ? typeof item[header]?.name === 'object'
                    //                     ? item[header]?.name?.default
                    //                     : item[header]?.name
                    //                 : headerData?.data?.data?.name?.default
                    // }
                    // if (header.includes('gallery')) {
                    //     return {
                    //         [header]: Array.isArray(item[header])
                    //             ? item[header][item[header].length - 1]?.innerName
                    //             : item[header]?.innerName
                    //     }
                    // }
                    // return dataItem
                    if (header.includes('gallery')) {
                        return {
                            [header]: Array.isArray(item[header])
                                ? item[header]?.[[item[header].length - 1]]?.innerName
                                : item[header]?.innerName
                        }
                    } else {
                        return {
                            [header]: typeof item[header]?.name === 'object'
                                ? item[header]?.name?.default
                                    ? item[header]?.name?.default
                                    : item[header]?.name?.ru
                                        ? item[header]?.name?.ru
                                        : item[header]?.name?.ua
                                            ? item[header]?.name?.ua
                                            : ''
                                : item[header]?.name
                        }
                    }
                } else {
                    return {
                        [header]: typeof item[header] === 'object'
                            ? item[header]?.default
                                ? item[header]?.default
                                : item[header]?.ru
                                    ? item[header]?.ru
                                    : item[header]?.ua
                                        ? item[header]?.ua
                                        : ''
                            : item[header]
                    }
                }
            })

            return Object.assign({}, ...headers)
        })

        const rowsPerPage = data.info && data.info.input.pagination.limit

        const customColumns = columns.map(item => {
            return {
                ...item,
                editComponent: props => {
                    const field = props.columnDef.field
                    const value = props.value
                    let fieldData = remoteData.filter(e => e.config.url.includes(field.slice(0, -3)))[0]
                    fieldData = fieldData?.data
                    fieldData = fieldData?.data

                    return <FormControl
                        variant="outlined"
                        className={'categories_filter'}
                    >
                        {
                            item.field === 'id'
                                ? value
                                : item.field.includes('_id')
                                && !item.field.includes('gallery')
                                ? <>
                                    <InputLabel
                                        id="demo-simple-select-outlined-label">
                                        {
                                            value !== undefined
                                                ? value.toString().length > 12
                                                ? value.slice(0, 12) + '...'
                                                : value.slice(0, 12)
                                                : ''
                                        }
                                    </InputLabel>
                                    <CustomSelect
                                        value={value}
                                        field={field}
                                        fieldData={fieldData}
                                        editProductData={editProductData}
                                        onChange={this.selectOnChangeHandler}
                                    />
                                </>
                                :
                                // <CustomTextField
                                //     id="outlined-basic"
                                //     label={value.toString().length > 12 ? value.toString().slice(0, 12) + '...' : value.toString().slice(0, 12)}
                                //     variant="outlined"
                                //     color="secondary"
                                //     onChange={(e) => {
                                //         this.editProductDataHandler(field, e)
                                //     }}
                                // />
                                item.field.includes('gallery')
                                    ? <RowField>
                                        <CustomTextField
                                            width={
                                                value
                                                    ? value.length > 12
                                                    ? 15 * 25
                                                    : value.length * 25
                                                    : 150
                                            }
                                            id="outlined-basic"
                                            label={value !== undefined
                                                ? value.length > 12
                                                    ? value.slice(0, 12) + '...'
                                                    : value
                                                : fileName
                                            }
                                            variant="outlined"
                                            color="secondary"
                                        />
                                        <RemoveImage
                                            src={Remove}
                                            alt={'Remove'}
                                            onClick={() => this.removeImageHandler(fieldData.filter(e => e.name === value)[0])}
                                        />
                                        <UploadImage
                                            image={fieldData.filter(data => data.name === value)[0]}
                                            addImageToProducts={this.addImageToProducts}
                                        />
                                    </RowField>
                                    : <CustomTextField
                                        width={
                                            value
                                                ? value.length > 12
                                                ? 15 * 15
                                                : value.length * 15
                                                : 150
                                        }
                                        id="outlined-basic"
                                        label={value.toString().length > 12 ? value.toString().slice(0, 12) + '...' : value.toString().slice(0, 12)}
                                        variant="outlined"
                                        color="secondary"
                                        onChange={(e) => {
                                            this.editProductDataHandler(field, e)
                                        }}
                                    />
                        }
                    </FormControl>
                },
            }
        })

        return (
            // <TableWrapper>
            <MaterialTable
                title={lpack.goods}
                columns={customColumns}
                data={filteredData}
                options={{
                    search: false,
                    sorting: false,
                    pageSizeOptions: false,
                    pageSize: typeof rowsPerPage === 'number' ? rowsPerPage : 25,
                    // maxBodyHeight: "55vh"
                }}
                editable={{
                    onRowAdd: newData => {
                        return this.addData(newData, productsAdd)
                    },
                    onRowUpdate: newData => {
                        // return this.updateData(newData, productsAdd)
                    },
                    onRowDelete: oldData => {
                        return this.deleteData(oldData, productsDelete)
                    }
                }}
                actions={[
                    {
                        icon: "add_box",
                        tooltip: "Add product",
                        position: "toolbar",
                        onClick: () => this.props.openModalHandler(null)
                    },
                    rowData => ({
                        icon: 'edit',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => this.props.openModalHandler(rowData),
                    })
                ]}
                icons={{
                    Add: () => <div style={{display: 'none'}}/>,
                    Edit: () => <div style={{display: 'none'}}/>
                }}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            rowsPerPage={rowsPerPage}
                            count={Math.ceil(data.info && data.info.total / rowsPerPage)}
                            page={currentPage}
                            onChangePage={(e, page) => {
                                this.setState({
                                    currentPage: page
                                })
                                this.handleChangePage(page)
                            }}
                            ActionsComponent={TablePaginationActions}
                        />
                    )
                }}
            />
            // </TableWrapper>
        );
    }
}
import React, {useEffect, useState} from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {lpack} from "../../components/Language/language";
import ProductsTable from "../../components/ProductsTable/ProductsTable";
import Cookies from 'js-cookie';
import TextField from "@material-ui/core/TextField";
import {
    GoodsWrapper,
    ProductsTableColorFilter,
    ProductsTableFilter,
    ProductsTableFilterByBiggerOrSmallerValue, ProductsTableFilterColumn,
    ProductsTableFilterSearch,
    ProductsTableFilterSearchImg, ProductsTableSearchFilter
} from "./Goods.styled";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import SearchIcon from '../../assets/img/search.svg'
import axios from "axios";
import {
    colors, galleryDelete,
    productsUrl, urlPath,
} from "../../config/config";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {ChromePicker, CirclePicker} from "react-color"
import remove from "../../components/Remove/remove";
import AddProduct from "../../components/AddProduct/AddProduct";
import Modal from "@material-ui/core/Modal";

let hash

if (Cookies.get('user')) {
    hash = Cookies.get('user')
} else if (sessionStorage.getItem('user')) {
    hash = sessionStorage.getItem('user')
} else {
    sessionStorage.setItem('user', window.location.hash.slice(1, 33))
}

function Goods(props) {
    const [remoteData, setRemoteData] = useState([])
    const [productsData, setProductsData] = useState([])
    const [selectedCategory, getSelectedCategory] = useState([])
    const [countries, setCountries] = useState([])
    const [tones, setTones] = useState([])
    const [stoneForms, setStoneForms] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [filterValue, setFilterValue] = useState('')
    const [operator, setOperator] = useState('')
    const [color, setColor] = useState('#FFFFFF')
    const [colorName, setColorName] = useState('')
    const [ascStatus, setAskStatus] = useState(false)
    const [descStatus, setDeskStatus] = useState(false)
    const [modalStatus, setModalStatus] = useState(false)
    const [editProductsData, setEditProductsData] = useState(null)

    useEffect(() => {
        setColorName(
            colors.filter(item => item.hex.toLowerCase() === color).length !== 0
                ? colors.filter(item => item.hex.toLowerCase() === color)[0].name
                : colorName
        )
    }, [color])

    useEffect(() => {
        setProductsData(props.data.productsData)
    }, [props])

    useEffect(() => {
        if (productsData.headers !== undefined || null) {
            Promise.all(Object.keys(productsData.headers).map(async header => {
                if (header.includes("_id")) {
                    return await axios.post(urlPath + header.slice(0, -3), {}, {
                        headers: {
                            Authorization: "Bearer " + hash,
                        },
                    })
                    //     .then(async res => {
                    //     const data = {
                    //         pagination: {
                    //             page: 0,
                    //             limit: res?.data?.info?.total
                    //         }
                    //     }
                    //     return await axios.post(urlPath + header.slice(0, -3), data, {
                    //         headers: {
                    //             Authorization: "Bearer " + hash,
                    //         },
                    //     })
                    // })
                }
            })).then(async (response) => {
                const responseData = response.filter(item => item !== undefined)
                await setRemoteData(responseData)

                if (responseData.status === 'NOAUTH') {
                    remove()
                }
            })
        }
    }, [productsData])

    const searchHandler = () => {
        const data = {
            search: {
                name: searchValue,
                description: searchValue
            }
        }
        axios.post(productsUrl, searchValue === "" ? {} : data, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then(response => {
            const data = response.data
            setProductsData(data)

            if (data.status === 'NOAUTH') {
                remove()
            }
        })
    }

    const ascHandler = (status) => {
        const data = {
            order: {
                name: 'asc'
            }
        }
        axios.post(productsUrl, status ? {} : data, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then(response => {
            const data = response.data
            setProductsData(data)

            if (data.status === 'NOAUTH') {
                remove()
            }
        })
    }

    const descHandler = (status) => {
        const data = {
            order: {
                name: 'desc'
            }
        }
        axios.post(productsUrl, status ? {} : data, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then(response => {
            const data = response.data
            setProductsData(data)

            if (data.status === 'NOAUTH') {
                remove()
            }
        })
    }

    const filterHandler = () => {
        const data = {
            filter: (
                operator &&
                filterValue &&
                selectedCategory.length !== 0 &&
                countries.length !== 0
            ) ? {
                price: {
                    operator: operator,
                    value: filterValue
                },
                categories_id: {
                    value: selectedCategory
                },
                countries_id: {
                    value: countries
                }
            } : (operator && filterValue)
                ? {
                    price: {
                        operator: operator,
                        value: filterValue
                    }
                } : (operator && filterValue) && selectedCategory.length !== 0
                    ? {
                        price: {
                            operator: operator,
                            value: filterValue
                        },
                        categories_id: {
                            value: selectedCategory
                        }
                    }
                    : (operator && filterValue) && countries.length !== 0
                        ? {
                            price: {
                                operator: operator,
                                value: filterValue
                            },
                            countries_id: {
                                value: countries
                            }
                        } : selectedCategory.length !== 0
                            ? {
                                categories_id: {
                                    value: selectedCategory
                                }
                            } : countries.length !== 0
                                ? {
                                    countries_id: {
                                        value: countries
                                    }
                                } : {}

        }
        axios.post(productsUrl, data, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then(response => {
            const data = response.data
            setProductsData(data)

            if (data.status === 'NOAUTH') {
                remove()
            }
        })
    }

    const colorHandler = () => {
        const data = {
            filter: {
                color: {
                    name: colorName,
                    value: color
                }
            }
        }
        axios.post(productsUrl, data, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then(response => {
            const data = response.data
            setProductsData(data)

            if (data.status === 'NOAUTH') {
                remove()
            }
        })
    }

    const categoriesHandler = e => {
        const options = e.target.value;
        getSelectedCategory(options);
    }

    const countriesHandler = e => {
        const options = e.target.value;
        setCountries(options);
    }

    const tonesHandler = e => {
        const options = e.target.value;
        setTones(options);
    }

    const stoneFormsHandler = e => {
        const options = e.target.value;
        setStoneForms(options);
    }

    const openModalHandler = data => {
        setModalStatus(true)
        setEditProductsData(data)
    }

    const closeModalHandler = () => {
        setModalStatus(false)
    }

    const getData = (galleryId) => {
        const data = {}
        axios.post(productsUrl, data, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then(response => {
            const data = response.data
            setProductsData(data)

            if (galleryId) {
                const id = data.data.filter(e => e.id === galleryId)[0]?.gallery_id.id
                axios.post(galleryDelete + `/${id}`, {}, {
                    headers: {
                        Authorization: "Bearer " + hash,
                    },
                })
            }

            if (data.status === 'NOAUTH') {
                // remove()
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <GoodsWrapper>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="danger">
                            <h4>{lpack.goods}</h4>
                        </CardHeader>
                        <CardBody>
                            <ProductsTableFilterByBiggerOrSmallerValue>
                                <ProductsTableFilter>
                                    <ProductsTableFilterColumn>
                                        <ProductsTableFilter>
                                            <FormControl variant="outlined" className={'select_filter'}>
                                                <InputLabel id="demo-simple-select-outlined-label">Operator</InputLabel>
                                                <Select
                                                    color="secondary"
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={operator}
                                                    onChange={(e) => setOperator(e.target.value)}
                                                    label="Operator"
                                                >
                                                    <MenuItem value={""}>None</MenuItem>
                                                    <MenuItem value={"<"}>{"<"}</MenuItem>
                                                    <MenuItem value={">"}>{">"}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                id="outlined-basic"
                                                label="Filter"
                                                variant="outlined"
                                                color="secondary"
                                                value={filterValue}
                                                className={'input_filter'}
                                                onChange={(e) => setFilterValue(e.target.value)}
                                            />
                                        </ProductsTableFilter>
                                        <ProductsTableFilter>
                                            <FormControl variant="outlined" className={'select_filter'}>
                                                <InputLabel id="demo-simple-select-outlined-label">Tone</InputLabel>
                                                <Select
                                                    multiple
                                                    color="secondary"
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={tones}
                                                    onChange={tonesHandler}
                                                    label="Tone"
                                                >
                                                    <MenuItem value={"..."}>{"..."}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl variant="outlined" className={'input_filter'}>
                                                <InputLabel id="demo-simple-select-outlined-label">Stone
                                                    forms</InputLabel>
                                                <Select
                                                    multiple
                                                    color="secondary"
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={stoneForms}
                                                    onChange={stoneFormsHandler}
                                                    label="Stone forms"
                                                >
                                                    <MenuItem value={"..."}>{"..."}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </ProductsTableFilter>
                                        <ProductsTableFilter>
                                            <FormControl variant="outlined" className={'categories_filter'}>
                                                <InputLabel
                                                    id="demo-simple-select-outlined-label">Categories</InputLabel>
                                                <Select
                                                    multiple
                                                    color="secondary"
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={selectedCategory}
                                                    onChange={categoriesHandler}
                                                    label="Categories"
                                                >
                                                    {
                                                        remoteData.map(data => {
                                                            if (data.config.url.includes('categories')) {
                                                                return data.data.data.map(category => {
                                                                    return (
                                                                        typeof category.name === 'string'
                                                                            ? <MenuItem
                                                                                key={Math.random()}
                                                                                value={category.id}
                                                                            >
                                                                                {category.name}
                                                                            </MenuItem>
                                                                            : <MenuItem
                                                                                key={Math.random()}
                                                                                value={category.id}
                                                                            >
                                                                                {category.name.default}
                                                                            </MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </ProductsTableFilter>
                                        <ProductsTableFilter>
                                            <FormControl variant="outlined" className={'categories_filter'}>
                                                <InputLabel
                                                    id="demo-simple-select-outlined-label">Countries</InputLabel>
                                                <Select
                                                    multiple
                                                    color="secondary"
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={countries}
                                                    onChange={countriesHandler}
                                                    label="Countries"
                                                >
                                                    {
                                                        remoteData?.map(data => {
                                                            if (data?.config?.url?.includes('countries')) {
                                                                return data?.data?.data?.map(country => {
                                                                    return (
                                                                        typeof country.name === 'string'
                                                                            ? <MenuItem
                                                                                key={Math.random()}
                                                                                value={country.id}
                                                                            >
                                                                                {country.name}
                                                                            </MenuItem>
                                                                            : <MenuItem
                                                                                key={Math.random()}
                                                                                value={country.id}
                                                                            >
                                                                                {country.name.default}
                                                                            </MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </ProductsTableFilter>
                                    </ProductsTableFilterColumn>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={filterHandler}
                                    >
                                        Filter
                                    </Button>
                                </ProductsTableFilter>
                                <ProductsTableFilterColumn>
                                    <ProductsTableFilter>
                                        <ChromePicker
                                            color={color}
                                            onChange={(color) => setColor(color.hex)}
                                        />
                                        <CirclePicker
                                            color={color && color}
                                            colors={colors.map(color => color.hex)}
                                            circleSize={40}
                                            onChange={(color) => setColor(color.hex)}
                                        />
                                    </ProductsTableFilter>
                                    <ProductsTableColorFilter>
                                        <TextField
                                            id="outlined-basic"
                                            label="Color"
                                            variant="outlined"
                                            color="secondary"
                                            value={
                                                colors.filter(item => item.hex.toLowerCase() === color).length !== 0
                                                    ? colors.filter(item => item.hex.toLowerCase() === color)[0].name
                                                    : colorName
                                            }
                                            className={'color_filter'}
                                            onChange={(e) => setColorName(e.target.value)}
                                        />
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            className={'color_filter-button'}
                                            onClick={colorHandler}
                                        >
                                            Filter by color
                                        </Button>
                                    </ProductsTableColorFilter>
                                </ProductsTableFilterColumn>
                            </ProductsTableFilterByBiggerOrSmallerValue>
                            <ProductsTableSearchFilter>
                                <ProductsTableFilterSearch>
                                    <TextField
                                        id="outlined-basic"
                                        label="Search"
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                    <ProductsTableFilterSearchImg
                                        src={SearchIcon}
                                        alt="Search"
                                        onClick={searchHandler}
                                    />
                                </ProductsTableFilterSearch>
                                <ButtonGroup
                                    color="secondary"
                                    aria-label="outlined secondary button group"
                                    className={"buttons_sort"}
                                >
                                    <Button onClick={() => {
                                        setAskStatus(!ascStatus)
                                        ascHandler(!ascStatus)
                                    }}>
                                        Asc
                                    </Button>
                                    <Button onClick={() => {
                                        setDeskStatus(!descStatus)
                                        descHandler(!descStatus)
                                    }}>
                                        Desk
                                    </Button>
                                </ButtonGroup>
                            </ProductsTableSearchFilter>
                            <Modal
                                open={modalStatus}
                                style={{overflow: 'auto'}}
                                onClose={closeModalHandler}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <AddProduct
                                    image={true}
                                    getData={getData}
                                    data={productsData}
                                    remoteData={remoteData}
                                    editData={editProductsData}
                                    setModalStatus={setModalStatus}
                                />
                            </Modal>
                            <ProductsTable
                                getData={getData}
                                data={productsData}
                                remoteData={remoteData}
                                openModalHandler={openModalHandler}
                                categoriesData={{}}
                                countriesData={{}}
                                galleriesData={{}}
                                manufactoriesData={{}}
                                usersData={{}}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </GoodsWrapper>
    );
}

export default Goods
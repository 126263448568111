import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router, Route, Switch, Redirect} from "react-router-dom";
import Admin from "layouts/Admin.js";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import CopointerAuth from "./views/Auth/Auth";
import {lpack} from "components/Language/language"
import Cookies from 'js-cookie';

let hash

if (Cookies.get('user')) {
    hash = Cookies.get('user')
} else if (sessionStorage.getItem('user')) {
    hash = sessionStorage.getItem('user')
} else {
    sessionStorage.setItem('user', window.location.hash.slice(1, 33))
}

const hist = createBrowserHistory();

if (window.location.protocol !== 'https:') {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

ReactDOM.render(
    <Router history={hist}>
        <Route path="/auth" render={() => <CopointerAuth language={lpack}/>}/>
        {
            hash
                ? <Switch>
                    <Route path="/admin" component={Admin}/>
                    <Redirect from="/" to="/admin/products"/>
                </Switch>
                : <Redirect to="/auth"/>
        }
    </Router>,
    document.getElementById("root")
);

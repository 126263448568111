import React, {useEffect, useState} from "react";
import {Switch, Route} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import logo from "assets/img/logo.svg";
import axios from "axios";
import remove from "../components/Remove/remove";
import Cookies from 'js-cookie';
import {productsUrl} from "../config/config";

let hash, ps

if (Cookies.get('user')) {
    hash = Cookies.get('user')
} else if (sessionStorage.getItem('user')) {
    hash = sessionStorage.getItem('user')
} else {
    sessionStorage.setItem('user', window.location.hash.slice(1, 33))
}
const switchRoutes = (type, data) => (
    <Switch>
        {routes.map(({path, name, icon, component: C, layout}, key) => {
            if (layout.includes(type)) {
                return (
                    <Route
                        path={'/admin' + path}
                        render={(props) => <C {...props} data={data}/>}
                        key={key}
                    />
                );
            }
        })}
    </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({...rest}) {
    let _isMounted = false
    const classes = useStyles();
    const mainPanel = React.createRef();
    const [productsData, setProductsData] = useState([]);
    const [wssStatus, setWssStatus] = useState(
        localStorage.getItem('connectStatus')
            ? localStorage.getItem('connectStatus') === 'true'
            : true
    );
    const [color, setColor] = React.useState("orange");
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
        return window.location.pathname !== "/admin/maps";
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    const getProductsData = () => {
        const data = {}
        axios.post(productsUrl, data, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then(response => {
            setProductsData(response.data)

            if (response.data.status === 'NOAUTH') {
                // remove()
            }
        })
    }

    const connectWss = connectStatus => {
        setWssStatus(connectStatus)
        localStorage.setItem('connectStatus', connectStatus)
    }

    useEffect(() => {
        _isMounted = true
        getProductsData()
        return () => {
            _isMounted = false
        }
    }, [])

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    }, [mainPanel]);

    return (
        <div className={classes.wrapper}>
            <Sidebar
                type={'/admin'}
                routes={routes}
                logo={logo}
                image={color}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={color}
                {...rest}
            />
            <div className={classes.mainPanel} ref={mainPanel}>
                <Navbar
                    routes={routes}
                    handleDrawerToggle={handleDrawerToggle}
                    connectWss={connectWss}
                    {...rest}
                />
                {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                {getRoute() ? (
                    <div className={classes.content}>
                        <div className={classes.container}>
                            {switchRoutes(
                                '/admin',
                                {
                                    productsData,
                                    wssStatus
                                }
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={classes.map}>
                        {switchRoutes(
                            '/admin',
                            {
                                productsData,
                                wssStatus
                            }
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

import styled from 'styled-components'

export const ProductsTableFilter = styled.div`
  width: 100%;
  display: flex;
  
  & .chrome-picker,
  & .circle-picker {
    margin-left: 5%;
  }
  
  @media screen and (min-width: 1024px) {
    & .buttons_sort {
      margin-left: 1%;
    }
  }
  
  @media screen and (max-width: 1024px) {
    & {
      flex-wrap: wrap;
    }
    
    & .buttons_sort {
      margin-bottom: 5%;
    }
  }
`

export const ProductsTableSearchFilter = styled(ProductsTableFilter)`
  margin: 2% 0;
`

export const ProductsTableColorFilter = styled(ProductsTableFilter)`
  margin-top: 5%;
  height: max-content;
  
  & .color_filter {
    width: 70%;
    margin-left: 5%;
  }
  
  & .color_filter-button {
    width: 30%;
    margin: 0 2%;
  }
`

export const ProductsTableFilterColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ProductsTableFilterSearch = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  
  @media screen and (max-width: 1024px) {
    & {
      margin-bottom: 5%;
    }
  }
`

export const ProductsTableFilterSearchImg = styled.img`
  top: 33%;
  right: 2%;
  width: 20px;
  cursor: pointer;
  position: absolute;
`

export const ProductsTableFilterByBiggerOrSmallerValue = styled(ProductsTableFilter)`
  width: 100%;
  
  & .select_filter {
    width: 50%;
  }
  
  & .input_filter {
    width: 50%;
    margin: 0 2%;
  }
  
  & .categories_filter {
    width: 98%;
  }
  
  & .MuiFormLabel-root.Mui-focused {
    color: #EB5757;
  }
`

export const GoodsWrapper = styled.div`
  & .MTableToolbar-actions-115 div div span button:nth-child(2) {
    display: none;
  }
  
  & .MuiTableRow-root:not([mode="delete"]) td div button:nth-child(2) {
    display: none;
  }
  
  & .MuiTableRow-root:not([mode="delete"]) td .makeStyles-root-121 button:nth-child(2) {
    display: inline-flex;
  }
`

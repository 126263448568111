import React, {useEffect, useState} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import {ExitToApp} from "@material-ui/icons";
import remove from "../Remove/remove";
import {lpack} from "../Language/language";
import Switch from '@material-ui/core/Switch';

let hash

if (window.location.hash.slice(-5) === '?true') {
    localStorage.setItem('user', window.location.hash.slice(1, 33))
}

if (window.location.hash.slice(-6) === '?false') {
    sessionStorage.setItem('user', window.location.hash.slice(1, 33))
}

hash = {
    "hash": localStorage.getItem('user')
        ? localStorage.getItem('user')
        : sessionStorage.getItem('user')
            ? sessionStorage.getItem('user')
            : window.location.hash.slice(1, 33)
}

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks(props) {
    const classes = useStyles();
    const [openProfile, setOpenProfile] = useState(null);
    const handleCloseProfile = () => {
        setOpenProfile(null);
        remove('close')
    };

    const [switchStatus, setSwitchStatus] = useState(
        localStorage.getItem('connectStatus')
            ? localStorage.getItem('connectStatus') === 'true'
            : true
    );

    const switchStatusHandler = event => {
        setSwitchStatus(event.target.checked)
        props.connectWss(event.target.checked)
    }

    return (
        <div>
            <div className={classes.manager} style={{display: 'flex', alignItems: 'center'}}>
                <Button
                    color={window.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-owns={openProfile ? "profile-menu-list-grow" : null}
                    aria-haspopup="true"
                    onClick={handleCloseProfile}
                    className={classes.buttonLink}
                >
                    <ExitToApp className={classes.icons}/>
                    <Hidden mdUp implementation="css">
                        <p className={classes.linkText}>Log out</p>
                    </Hidden>
                </Button>
            </div>
        </div>
    );
}

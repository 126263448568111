import React, {Component} from "react";
import "./login.css"
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import {isEmail} from "validator";
import {lpack} from "components/Language/language"
import Cookies from "js-cookie";

const email = value => {
    if (!isEmail(value)) {
        return (
            <div className="copointerAuth_signIn-error" role="alert">
                {lpack.notValidEmail}
            </div>
        );
    }
};

const vpassword = value => {
    if (value.length < 5 || value.length > 40) {
        return (
            <div className="copointerAuth_signIn-error" role="alert">
                {lpack.notValidPassword}
            </div>
        );
    }
};

const required = () => {
    return (
        <div className="copointerAuth_signIn-error" role="alert">
            {lpack.fieldRequired}
        </div>
    );
};

export default class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            loading: false,
            message: "",
            login: false,
            checked: false,
            data: null,
            wrongAcc: true,
            emailFieldEmpty: false,
            passwordFieldEmpty: false,
            accountStatus: null
        };
    }

    onChangeEmail = e => {
        this.setState({
            email: e.target.value,
            emailFieldEmpty: e.target.value === ''
        });
        email(e.target.value)
    }

    onChangePassword = e => {
        this.setState({
            password: e.target.value,
            passwordFieldEmpty: e.target.value === ''
        });
        vpassword(e.target.value)
    }

    rememberMe = e => {
        this.setState({
            checked: e.target.checked
        })
    }

    reverseString = str => {
        return str.split("").reverse().join("");
    }

    handleLogin = e => {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true

        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            AuthService.login(
                this.state.email,
                this.state.password,
                this.state.checked
            ).then(
                response => {
                    const data = response.data
                    if (data.statistics === 'OK') {
                        this.setState({wrongAcc: true, accountStatus: true})
                        Cookies.set('user', data.auth, { expires: 7 })
                        window.location.assign(
                            `${window.location.origin}/admin/products/#${data.auth}?${this.state.checked && "remember=1"}`
                        )
                    } else if (data.status === 'WRNG') {
                        this.setState({wrongAcc: false, accountStatus: false})
                    }
                }
            );
        } else {
            this.setState({
                loading: false
            });
        }
    }

    componentDidMount() {
        if (window.location.search === '?no_data') {
            this.setState({data: false, accountStatus: false})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.data === false) {
            setTimeout(() => this.setState({data: true}), 3000)
        }
        if (this.state.wrongAcc === false) {
            setTimeout(() => this.setState({wrongAcc: true}), 3000)
        }
    }

    render() {
        const {data, message, wrongAcc, email, password, emailFieldEmpty, passwordFieldEmpty, accountStatus} = this.state
        const {language} = this.props
        return (
            <>
                <h2 className="copointerAuth_signIn-title">
                    {language.copointerAuthSignInTitle}
                </h2>
                {
                    data === false
                        ? <div className="copointerAuth_signIn-error" role="alert">
                            {language.oldSession}
                        </div>
                        : null
                }
                {
                    message
                        ? <div className="copointerAuth_signIn-error" role="alert">
                            {message}
                        </div>
                        : null
                }
                {
                    !wrongAcc
                        ? <div className="copointerAuth_signIn-error" role="alert">
                            {language.wrongData}
                        </div>
                        : null
                }
                {
                    accountStatus
                        ? <Form className="copointerAuth_signIn-form">
                            <div className="copointerAuth_signIn-description">
                                {language.loading}
                            </div>
                            <div className="loader"/>
                        </Form>
                        : <Form
                            onSubmit={this.handleLogin}
                            ref={c => this.form = c}
                            className="copointerAuth_signIn-form"
                        >
                            <input
                                type="text"
                                className="copointerAuth_signIn-data"
                                name="email"
                                value={email}
                                onChange={this.onChangeEmail}
                                placeholder={language.placeholderSignInEmail}
                            />
                            {
                                emailFieldEmpty
                                    ? required()
                                    : null
                            }
                            <input
                                type="password"
                                className="copointerAuth_signIn-data"
                                name="password"
                                value={password}
                                onChange={this.onChangePassword}
                                placeholder={language.placeholderSignInPassword}
                            />
                            {
                                passwordFieldEmpty
                                    ? required()
                                    : null
                            }
                            <label className="container">
                                {language.rememberMe}
                                <input type="checkbox" onChange={this.rememberMe} checked={this.state.checked}/>
                                <span className="checkmark"/>
                            </label>
                            <CheckButton
                                style={{display: "none"}}
                                ref={c => this.checkBtn = c}
                            />
                            <button className="copointerAuth_signIn-button">
                                {language.copointerAuthSignInTitle}
                            </button>
                        </Form>
                }
            </>
        );
    }
}
import React, {Component} from "react";
import LoginForm from "./loginForm";
import ForgotPassword from "../forgotPassword/forgotPassword";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggle: false
        }
    }

    toggleBackToLogin = () => {
        this.setState({
            toggle: false
        });
    }

    toggleForgotPassword = () => {
        this.setState({
            toggle: true
        });
    }

    render() {
        const toggle = this.state.toggle;
        const {language} = this.props

        let button;

        if (toggle) {
            button = <ButtonBackToLogin onClick={this.toggleBackToLogin} language={language}/>
        } else {
            button = <ButtonForgotPassword onClick={this.toggleForgotPassword} language={language}/>
        }

        return (
            <>
                <Auth toggle={toggle} language={language}/>
                {button}
            </>
        )
    }
}

function Auth(props) {
    const toggle = props.toggle;
    if (toggle) {
        return <ForgotPassword language={props.language}/>;
    }
    return <LoginForm language={props.language}/>;
}

function ButtonForgotPassword(props) {
    return <span className="copointerAuth_signIn-forgotPassword" onClick={props.onClick}>
            {props.language.copointerAuthSignInForgotPassword}
        </span>
}

function ButtonBackToLogin(props) {
    return <span className="copointerAuth_signIn-forgotPassword" onClick={props.onClick}>
            {props.language.copointerAuthForgotPassword}
        </span>
}
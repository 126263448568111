export const productsUrl = "https://api.kanyon.com.ua/get/products"
export const productsAdd = "https://api.kanyon.com.ua/add/products"
export const productsDelete = "https://api.kanyon.com.ua/delete/products"
export const categoriesUrl = "https://api.kanyon.com.ua/add/categories"
export const categoriesDelete = "https://api.kanyon.com.ua/delete/categories"
export const pagesUrl = "https://api.kanyon.com.ua/add/pages"
export const pagesDelete = "https://api.kanyon.com.ua/delete/pages"
export const countriesUrl = "https://api.kanyon.com.ua/add/countries"
export const countriesDelete = "https://api.kanyon.com.ua/delete/countries"
export const colorsUrl = "https://api.kanyon.com.ua/add/colors"
export const colorsDelete = "https://api.kanyon.com.ua/delete/colors"
export const galleryUrl = "https://api.kanyon.com.ua/gallery"
export const galleryDelete = "https://api.kanyon.com.ua/delete/gallery"
export const manufactoriesUrl = "https://api.kanyon.com.ua/add/manufactories"
export const manufactoriesDelete = "https://api.kanyon.com.ua/delete/manufactories"
export const urlPath = "https://api.kanyon.com.ua/get/"
export const getImage = "http://api.kanyon.com.ua/contents/"

export const colors = [
    {hex: "#f5f5dc", name: "Beige"},
    {hex: "#FFFFFF", name: "White"},
    {hex: "#42AAFF", name: "Dodger Blue"},
    {hex: "#FFFF00", name: "Yellow"},
    {hex: "#008000", name: "Green"},
    {hex: "#964B00", name: "Brown"},
    {hex: "#FF0000", name: "Red"},
    {hex: "#FFC0CB", name: "Pink"},
    {hex: "#808080", name: "Grey"},
    {hex: "#0000FF", name: "Blue"},
    {hex: "#C8A2C8", name: "Lilac"},
    {hex: "#B0C4DE", name: "Pigeon Post"},
    {hex: "#8B00FF", name: "Violet"},
    {hex: "#000000", name: "Black"},
]

export const languages = [
    {
        title: 'EN',
        value: 'default'
    },
    {
        title: 'RU',
        value: 'ru'
    },
    {
        title: 'UA',
        value: 'ua'
    },
]
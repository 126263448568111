// imports the React Javascript Library
import React from "react";
//Card
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";

import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

import red from "@material-ui/core/colors/red";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import CollectionsIcon from "@material-ui/icons/Collections";

// Search
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from "@material-ui/icons/Replay";

//Tabs
import {withStyles} from "@material-ui/core/styles";
import {getImage} from "../../config/config";

const styles = theme => ({
    root: {
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: 'none'
    },
    icon: {
        margin: theme.spacing.unit * 2
    },
    iconHover: {
        margin: theme.spacing.unit * 2,
        "&:hover": {
            color: red[800]
        }
    },
    cardHeader: {
        textalign: "center",
        align: "center",
        backgroundColor: "white"
    },
    input: {
        display: "none"
    },
    title: {
        color: red[800],
        fontWeight: "bold",
        fontFamily: "Montserrat",
        align: "center"
    },
    button: {
        color: red[900],
        margin: 10,
        backgroundColor: 'white'
    },
    secondaryButton: {
        color: "gray",
        margin: 10
    },
    typography: {
        margin: theme.spacing.unit * 2,
        backgroundColor: "default"
    },
});

class UploadImage extends React.Component {
    state = {
        mainState: "initial", // initial, search, gallery, uploaded
        imageUploaded: 0,
        selectedFile: null,
        mimeType: null,
        name: null,
        file: null,
    };

    handleUploadClick = event => {
        let file = event.target.files[0];
        const reader = new FileReader();
        let url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            this.props.addImageToProducts(file.name, file.type, reader.result.slice(reader.result.indexOf(',') + 1))
            this.setState({
                selectedFile: [reader.result],
                mimeType: file.type,
                name: file.name,
                file: reader.result.slice(reader.result.indexOf(',') + 1),
            });
        }.bind(this);

        this.setState({
            mainState: "uploaded",
            selectedFile: event.target.files[0],
            imageUploaded: 1
        });
    };

    handleGalleryClick = event => {
        this.setState({
            mainState: "gallery"
        });
    };

    renderInitialState() {
        const {classes, theme} = this.props;
        const {value} = this.state;

        return (
            <React.Fragment>
                <CardContent>
                    <Grid container justify="center" alignItems="center">
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={this.handleUploadClick}
                        />
                        <label htmlFor="contained-button-file">
                            <Fab component="span" className={classes.button}>
                                <AddPhotoAlternateIcon/>
                            </Fab>
                        </label>
                        {/*<Fab className={classes.button} onClick={this.handleGalleryClick}>*/}
                        {/*    <CollectionsIcon />*/}
                        {/*</Fab>*/}
                    </Grid>
                </CardContent>
            </React.Fragment>
        );
    }

    handleSearchClose = event => {
        this.setState({
            mainState: "initial"
        });
    };

    handleAvatarClick(value) {
        let filename = value.url.substring(value.url.lastIndexOf("/") + 1);
        this.setState({
            mainState: "uploaded",
            imageUploaded: true,
            selectedFile: value.url,
            fileReader: undefined,
            filename: filename
        });
    }

    renderGalleryState() {
        const {classes} = this.props;
        const galleryImageList = [
            "https://raw.githubusercontent.com/dxyang/StyleTransfer/master/style_imgs/mosaic.jpg",
            "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg/1280px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg",
            "https://raw.githubusercontent.com/ShafeenTejani/fast-style-transfer/master/examples/dora-maar-picasso.jpg",
            "https://pbs.twimg.com/profile_images/925531519858257920/IyYLHp-u_400x400.jpg",
            "https://raw.githubusercontent.com/ShafeenTejani/fast-style-transfer/master/examples/dog.jpg",
            "http://r.ddmcdn.com/s_f/o_1/cx_462/cy_245/cw_1349/ch_1349/w_720/APL/uploads/2015/06/caturday-shutterstock_149320799.jpg"
        ];
        const listItems = galleryImageList.map(url => (
            <div
                onClick={value => this.handleAvatarClick({url})}
                style={{
                    padding: "5px 5px 5px 5px",
                    cursor: "pointer"
                }}
            >
                <Avatar src={url}/>
            </div>
        ));

        return (
            <React.Fragment>
                <Grid>
                    {listItems}
                    <IconButton
                        color="primary"
                        className={classes.secondaryButton}
                        aria-label="Close"
                        onClick={this.handleSearchClose}
                    >
                        <ReplayIcon/>
                    </IconButton>
                </Grid>
            </React.Fragment>
        );
    }

    renderUploadedState() {
        const {classes, editing, editData} = this.props;

        return (
            <React.Fragment>
                <CardActionArea onClick={this.imageResetHandler}>
                    <img
                        width="100%"
                        className={classes.media}
                        src={
                            editing
                                ? this.state.selectedFile
                                ? this.state.selectedFile
                                : getImage + editData?.gallery_id
                                : this.state.selectedFile
                                ? this.state.selectedFile
                                : `data:${this.props.image?.mimetype};base64,${this.props.image?.content}`
                        }
                        alt={'File'}
                    />
                </CardActionArea>
            </React.Fragment>
        );
    }

    imageResetHandler = event => {
        this.setState({
            mainState: "initial",
            selectedFile: null,
            imageUploaded: 0
        });
    };

    componentDidMount() {
        if (this.props.image) {
            this.setState({
                mainState: 'uploaded'
            })
        }
    }

    render() {
        const {classes, theme} = this.props;

        return (
            <React.Fragment>
                <div className={classes.root}>
                    <Card className={classes.root}>
                        {/*{*/}
                        {/*    (this.state.mainState === "initial" && this.renderInitialState()) ||*/}
                        {/*    (this.state.mainState === "gallery" && this.renderGalleryState()) ||*/}
                        {/*    (this.state.mainState === "uploaded" && this.renderUploadedState())*/}
                        {/*}*/}
                        {this.renderInitialState()}
                        {this.renderUploadedState()}
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(styles, {withTheme: true})(UploadImage);

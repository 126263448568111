import React, {useState} from "react";
import styled from 'styled-components'
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const CustomSelector = styled(Select)`
  width: 150px;
`

function CustomSelect(props) {
    const {editProductData, field, fieldData, onChange, value} = props
    const [selectedValue, setSelectedValue] = useState('')

    return (
        <CustomSelector
            // multiple
            color="secondary"
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            className={field}
            value={selectedValue ? selectedValue : editProductData?.filter(e => e.field === field).map(e => e.value)[0]}
            onChange={(e) => {
                onChange(editProductData.filter(el => el.field === field).length === 0, field, e.target.value)
                setSelectedValue(e.target.value.name)
                const select = document.querySelectorAll('#demo-simple-select-outlined')
                select.forEach(item => {
                    if (item.parentElement.classList.contains(field)) {
                        item.parentElement.firstChild.firstChild.innerHTML = e.target.value.name
                    }
                })
            }}
            label={
                selectedValue
                    ? selectedValue
                    : value !== undefined
                    ? value.toString().length > 12
                        ? value.slice(0, 12) + '...'
                        : value.slice(0, 12)
                    : selectedValue
            }
        >
            {
                fieldData?.map(e => {
                    return (
                        typeof e.name === 'string'
                            ? <MenuItem
                                key={Math.random()}
                                value={{
                                    id: e.id,
                                    name: e.name,
                                    description: e.name
                                }}
                            >
                                {e.name}
                            </MenuItem>
                            : e.name !== undefined && <MenuItem
                            key={Math.random()}
                            value={{
                                id: e.id,
                                name: e.name.default,
                                description: e.name.default
                            }}
                        >
                            {e.name.default}
                        </MenuItem>
                    )
                })
            }
        </CustomSelector>
    )
}

export default CustomSelect
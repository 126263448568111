import React, {Component} from "react";
import axios from "axios";
import Cookies from 'js-cookie';

const hash = Cookies.get("user")

const API_URL_AUTH = "https://api.kanyon.com.ua/auth?login=test&password=test&remember=1";
const API_URL_REG = "https://copointer.com:7777/registration/";
const API_URL_ACT = "https://copointer.com:7777/activation/";

class AuthService extends Component {
    login = (email, password, checked) => {
        return axios
            .post(`https://api.kanyon.com.ua/auth?login=${email}&password=${password}&${checked && "remember=1"}`)
            .then(
                response => {
                    console.log(response)
                    console.log(Cookies.get())
                    if (response.data.status === 'OK') {
                        Cookies.set('user', response.data.auth, { expires: 7 })
                        window.location.assign(`${window.location.origin}/admin/products/#${response.data.auth}`)
                    } else if (response.data.status === 'WRNG') {
                        window.location.assign(`${window.location.origin}/auth?no_data`)
                    }
                }
            )
    }

    register = (email, password) => {
        return axios.post(API_URL_REG, {
            email,
            password
        })
    }

    activation = (token) => {
        if (!token) {
            return null
        } else {
            return axios.post(API_URL_ACT, {
                token
            }).then(
                response => {
                    if (response.data.hash) {
                        window.location.assign(
                            `${window.location.origin}/admin/dashboard/#${response.data.hash}?false`
                        )
                    } else if (response.data.error === 'WRONGACC') {
                        window.location.assign(
                            `${window.location.origin}/auth?no_data`
                        )
                    }
                }
            )
        }
    }

    getCurrentUser = () => {
        return JSON.parse(localStorage.getItem('user'));
    }
}

export default new AuthService();
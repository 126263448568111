/*!

=========================================================
* Material Goods React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components/views for Admin layout
import {lpack} from "./components/Language/language";
import Goods from "./views/Goods/Goods";
import Categoties from "./views/Categories/Categories";
import Countries from "./views/Countries/Countries";
import Colors from "./views/Colors/Colors";
import Gallery from "./views/Gallery/Gallery";
import Manufacturies from "./views/Manufacturies/Manufacturies";
import Pages from "./views/Pages/Pages";
// core components/views for RTL layout


const dashboardRoutes = [
  {
    path: "/products",
    name: lpack.goods,
    icon: Dashboard,
    component: Goods,
    layout: "/admin"
    // layout: ["/admin", "/manager"]
  },
  {
    path: "/categories",
    name: lpack.categories,
    icon: Dashboard,
    component: Categoties,
    layout: "/admin"
    // layout: ["/admin", "/manager"]
  },
  {
    path: "/countries",
    name: lpack.countries,
    icon: Dashboard,
    component: Countries,
    layout: "/admin"
    // layout: ["/admin", "/manager"]
  },
  {
    path: "/colors",
    name: lpack.colors,
    icon: Dashboard,
    component: Colors,
    layout: "/admin"
    // layout: ["/admin", "/manager"]
  },
  // {
  //   path: "/gallery",
  //   name: lpack.gallery,
  //   icon: Dashboard,
  //   component: Gallery,
  //   layout: "/admin"
  //   // layout: ["/admin", "/manager"]
  // },
  {
    path: "/manufacturies",
    name: lpack.manufacturies,
    icon: Dashboard,
    component: Manufacturies,
    layout: "/admin"
    // layout: ["/admin", "/manager"]
  },
  {
    path: "/pages",
    name: lpack.pages,
    icon: Dashboard,
    component: Pages,
    layout: "/admin"
    // layout: ["/admin", "/manager"]
  },
];

export default dashboardRoutes;

let lang = navigator.language.split('-')[0].toLowerCase(),
date = new Date()
date = date.toLocaleTimeString()
let languagePack = {
    "en": {
        goods: "Products",
        categories: "Categories",
        countries: "Countries",
        colors: "Colors",
        gallery: "Gallery",
        manufacturies: "Manufacturies",
        statistics: "Statistics",
        staff: "Staff",
        callRequestVolume: "Call request volume:",
        startSession: "Init. DateTime",
        status: "Status",
        location: "Url",
        sessionDuration: "Session duration",
        title: "Request history",
        balance: "Balance:",
        expireDate: "Expire date:",
        getCode: "Get code:",
        copy: "Copy",
        codeWasCopied: "Code was copied!",
        pasteCode: "Paste this code into the",
        inFile: "of your main html file.",
        callsToAdminDescription: "Calls from users if they have problems with the tool",
        userCalls: "Room activation:",
        managerCalls: "Manager answers:",
        statisticsTitle: "Statistics for the past 7 days",
        statisticsUpdateTime: `updated ${date}`,
        globalStatisticsTable: "Global Statistics",
        globalStatisticsDescription: "Global statistics includes data about date and domain",
        selectFirstDay: "Please select the first day.",
        selectLastDay: "Please select the last day.",
        selectedFrom: "Selected from",
        selectedTo: "to",
        apply: "Apply",
        reset: "Reset",
        globalStatisticsDate: "Date",
        globalStatisticsRooms: "Rooms",
        globalStatisticsOnline: "Online",
        globalStatisticsCallsManager: "Calls manager",
        domainStatisticsTitle: "Domain Statistics",
        domainStatisticsDescription: "Domain statistics includes data about domain",
        domainStatisticsDomain: "Domain",
        staffTitle: "Staff",
        staffDescription: "Staff that have access to the tool",
        staffId: "Id",
        staffNickName: "Username",
        staffFirstName: "First Name",
        staffLastName: "Last Name",
        staffEmail: "Email",
        staffPassword: "Password",
        staffStatus: "Status",
        staffDate: "Date",
        noRecords: "No records to display",
        message: "Message",
        messageTitle: "Message queue",
        messageDescription: "Messages from users in chat",
        roomNumber: "Room number",
        titleChatHistory: "Chat history",
        activeRoomsTitle: "Active rooms",
        passiveRooms: "No one room is active",
        openChatHistory: "Open chat history",
        joinThisChat: "Join the site",
        chatTitle: "Chat",
        newChats: "New chats",
        pendingChats: "Pending chats",
        activeChats: "Active chats",
        close: "Close",
        unreadMessages: "Unread messages",
        lastMessageInChat: "The last message in chat",
        noMessagesYet: "No messages yet",
        ticketNumber: "Card number",
        authDesktop: "Sign Up / Sign In",
        copointerAuthSignInTitle: "Sign In",
        placeholderSignInEmail: "Email",
        placeholderSignInPassword: "Password",
        rememberMe: "Remember Me",
        copointerAuthSignInForgotPassword: "Forgot Password",
        copointerAuthSignUp: "DON'T HAVE AN ACCOUNT? SIGN UP",
        copointerAuthSignUpTitle: "Sign Up",
        copointerAuthSignIn: "ALREADY HAVE AN ACCOUNT? SIGN IN",
        copointerForgotPasswordButton: "Submit",
        copointerAuthForgotPassword: "Back to Sign In",
        oldSession: "Your session is out of date",
        wrongData: "Wrong email or password",
        loading: "Loading",
        fieldRequired: "This field is required!",
        notValidEmail: "This is not a valid email.",
        notValidPassword: "The password must be between 6 and 40 characters.",
        accountCreated: "Thanks! Your account has been successfully created.",
        checkYourInbox: "Please check your inbox, activation link is sent on your email. If you see it, click on it and wait while we activating your account.",
        deleteMessage: "Delete",
        saveMessage: "Save",
        editMessage: "Edit",
        break: "Break",
        pages: "Pages",
    },
    "ru": {
        goods: "Товары",
        categories: "Категории",
        countries: "Страны",
        colors: "Цвета",
        gallery: "Галерея",
        manufacturies: "Производство",
        statistics: "Статистика",
        staff: "Сотрудники",
        callRequestVolume: "Громкость вызова менеджера:",
        startSession: "Дата",
        status: "Статус",
        location: "Ссылка",
        sessionDuration: "Продолжительность сессии",
        title: "История запросов",
        balance: "Счёт:",
        expireDate: "Дата истечения:",
        getCode: "Получить код:",
        copy: "Скопировать",
        codeWasCopied: "Код был скопирован!",
        pasteCode: "Вставьте этот код в",
        inFile: "вашего главного html файла.",
        callsToAdminDescription: "Звонки от пользователей, если у них проблемы с инструментом",
        userCalls: "Активация комнат:",
        managerCalls: "Ответы менеджера:",
        statisticsTitle: "Статистика за последние 7 дней",
        statisticsUpdateTime: `обновлено ${date}`,
        globalStatisticsTable: "Общая статистика",
        globalStatisticsDescription: "Общая статистика включает данные о дате и домене",
        selectFirstDay: "Пожалуйста, выберите первый день.",
        selectLastDay: "Пожалуйста, выберите последний день.",
        selectedFrom: "Выбрано от",
        selectedTo: "до",
        apply: "Применить",
        reset: "Сбросить",
        globalStatisticsDate: "Дата",
        globalStatisticsRooms: "Комнаты",
        globalStatisticsOnline: "В сети",
        globalStatisticsCallsManager: "Вызов менеджера",
        domainStatisticsTitle: "Статистика домена",
        domainStatisticsDescription: "Статистика домена включает данные о домене",
        domainStatisticsDomain: "Домен",
        staffTitle: "Сотрудники",
        staffDescription: "Сотрудники, имеющие доступ к инструменту",
        staffId: "Идентификатор",
        staffNickName: "Пользовательское имя",
        staffFirstName: "Имя",
        staffLastName: "Фамилия",
        staffEmail: "Электронная почта",
        staffPassword: "Пароль",
        staffStatus: "Статус",
        staffDate: "Дата",
        noRecords: "Нет записей для отображения",
        message: "Сообщение",
        messageTitle: "Очередь сообщений",
        messageDescription: "Сообщения от пользователей в чате",
        roomNumber: "Номер комнаты",
        titleChatHistory: "История чата",
        activeRoomsTitle: "Активные комнаты",
        passiveRooms: "Ни одна комната не активна",
        openChatHistory: "Открыть историю чата",
        joinThisChat: "Присоединиться на сайте",
        chatTitle: "Чат",
        newChats: "Новые чаты",
        pendingChats: "Чаты в ожидании",
        activeChats: "Активные чаты",
        close: "Закрыть",
        unreadMessages: "Непрочитанные сообщения",
        lastMessageInChat: "Последнее сообщение в чате",
        noMessagesYet: "Пока что сообщений нету",
        ticketNumber: "Номер сессии",
        authDesktop: "Зарегистрироваться / Войти",
        copointerAuthSignInTitle: "Войти",
        placeholderSignInEmail: "Почта",
        placeholderSignInPassword: "Пароль",
        rememberMe: "Запомнить меня",
        copointerAuthSignInButton: "Войти",
        copointerAuthSignInForgotPassword: "Забыли пароль?",
        copointerAuthSignUp: "НЕТ УЧЕТНОЙ ЗАПИСИ? РЕГЕСТРИРУЙТЕСЬ",
        copointerAuthSignUpTitle: "Зарегистрироваться",
        copointerAuthSignUpButton: "Зарегистрироваться",
        copointerAuthSignIn: "УЖЕ ЕСТЬ АККАУНТ? ВОЙТИ",
        copointerForgotPasswordButton: "Подтвердить",
        copointerAuthForgotPassword: "Вернуться к входу",
        oldSession: "Ваша сессия устарела",
        wrongData: "Неправильная почта или пароль",
        loading: "Загрузка",
        fieldRequired: "Это поле обязательно к заполнению!",
        notValidEmail: "Это недействительный адрес электронной почты.",
        notValidPassword: "Пароль должен содержать от 6 до 40 символов.",
        accountCreated: "Спасибо! Ваша учетная запись была успешно создана.",
        checkYourInbox: "Пожалуйста, проверьте свой почтовый ящик, ссылка для активации будет отправлена на вашу электронную почту. Если вы его видите, нажмите на него и подождите, пока мы активируем вашу учетную запись.",
        deleteMessage: "Удалить",
        saveMessage: "Сохранить",
        editMessage: "Редактировать",
        break: "Перерыв",
        pages: "Страницы",
    },
    "uk": {
        goods: "Товари",
        categories: "Категорії",
        countries: "Країни",
        colors: "Кольори",
        gallery: "Галерея",
        manufacturies: "Виробництво",
        statistics: "Статистика",
        staff: "Співробітники",
        callRequestVolume: "Гучність виклику менеджера:",
        startSession: "Дата",
        status: "Статус",
        location: "Посилання",
        sessionDuration: "Тривалість сесії",
        title: "Iсторiя запитів",
        balance: "Дата завершення:",
        getCode: "Отримати код:",
        copy: "Скопіювати",
        codeWasCopied: "Код був скопійований!",
        pasteCode: "Вставте цей код в",
        inFile: "вашого головного html файлу.",
        callsToAdminDescription: "Дзвінки від користувачів, якщо вони мають проблеми з інструментом",
        userCalls: "Активація кімнат:",
        managerCalls: "Відповіді менеджера:",
        statisticsTitle: "Статистика за останні 7 днів",
        statisticsUpdateTime: `оновлено ${date}`,
        globalStatisticsTable: "Загальна статистика",
        globalStatisticsDescription: "Загальна статистика включає дані про дату та домен",
        selectFirstDay: "Будь ласка, виберіть перший день.",
        selectLastDay: "Будь ласка, виберіть останній день.",
        selectedFrom: "Обрано від",
        selectedTo: "до",
        apply: "Застосувати",
        reset: "Скинути",
        globalStatisticsDate: "Дата",
        globalStatisticsRooms: "Кімнати",
        globalStatisticsOnline: "В мережі",
        globalStatisticsCallsManager: "Виклик менеджера",
        domainStatisticsTitle: "Статистика домену",
        domainStatisticsDescription: "Статистика домену включає дані про домен",
        domainStatisticsDomain: "Домен",
        staffTitle: "Співробітники",
        staffDescription: "Співробітники, які мають доступ до інструменту",
        staffId: "Ідентифікатор",
        staffNickName: "Користувацьке iм'я",
        staffFirstName: "Ім'я",
        staffLastName: "Прізвище",
        staffEmail: "Електронна пошта",
        staffPassword: "Пароль",
        staffStatus: "Статус",
        staffDate: "Дата",
        noRecords: "Немає записів для відображення",
        message: "Повідомлення",
        messageTitle: "Черга повідомлень",
        messageDescription: "Повідомлення від користувачів в чаті",
        roomNumber: "Номер кiмнати",
        titleChatHistory: "Iсторiя чату",
        activeRoomsTitle: "Активнi кiмнати",
        passiveRooms: "Жодна кімната не активна",
        openChatHistory: "Відкрити історію чату",
        joinThisChat: "Приєднатися на сайті",
        chatTitle: "Чат",
        newChats: "Нові чати",
        pendingChats: "Чати в очікуванні",
        activeChats: "Активні чати",
        close: "Закрити",
        unreadMessages: "Непрочитані повідомлення",
        lastMessageInChat: "Останнє повідомлення в чаті",
        noMessagesYet: "Поки що повідомлень немає",
        ticketNumber: "Номер сесії",
        authDesktop: "Зареєструватися / Увійти",
        copointerAuthSignInTitle: "Увійти",
        placeholderSignInEmail: "Пошта",
        placeholderSignInPassword: "Пароль",
        rememberMe: "Запам'ятати мене",
        copointerAuthSignInButton: "Увійти",
        copointerAuthSignInForgotPassword: "Забули пароль?",
        copointerAuthSignUp: "НЕ МАЄТЕ АККАУНТА? РЕЄСТРУЙТЕСЯ",
        copointerAuthSignUpTitle: "Зареєструватися",
        copointerAuthSignUpButton: "Зареєструватися",
        copointerAuthSignIn: "ВЖЕ Є АККАУНТ? УВІЙТИ",
        copointerForgotPasswordButton: "Підтвердити",
        copointerAuthForgotPassword: "Повернутися до входу",
        oldSession: "Ваша сесія застаріла",
        wrongData: "Неправильна пошта або пароль",
        loading: "Завантаження",
        fieldRequired: "Це поле є обов`язковим для заповнення!",
        notValidEmail: "Це недійсний адресу електронної пошти.",
        notValidPassword: "Пароль повинен містити від 6 до 40 символів.",
        accountCreated: "Спасибі! Ваш обліковий запис була успішно створена.",
        checkYourInbox: "Будь ласка, перевірте свою поштову скриньку, посилання для активації буде відправлена на вашу електронну пошту. Якщо ви його бачите, натисніть на нього і почекайте, поки ми активуємо ваш обліковий запис.",
        deleteMessage: "Видалити",
        saveMessage: "Зберегти",
        editMessage: "Редагувати",
        break: "Перерва",
        pages: "Сторінки",
    },
}

export let lpack = (languagePack[lang] ? languagePack[lang] : languagePack['en']);
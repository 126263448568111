import styled from 'styled-components'

export const ColorsWrapper = styled.div`
  & .MTableToolbar-actions-115 {
    display: none;
  }
`

export const ColorsTableFilter = styled.div`
  width: 100%;
  display: flex;
  
  & .circle-picker {
    margin-left: 5%;
  }
  
  @media screen and (min-width: 1024px) {
    & .buttons_sort {
      margin-left: 1%;
    }
  }
  
  @media screen and (max-width: 1024px) {
    & {
      flex-wrap: wrap;
    }
    
    & .buttons_sort {
      margin-bottom: 5%;
    }
  }
`

export const ColorsTableColorFilter = styled(ColorsTableFilter)`
  margin-top: 3%;
  height: max-content;
  
  & .color_filter {
    width: 70%;
  }
  
  & .color_filter-button {
    width: 30%;
    margin-left: 2%;
  }
`

export const ColorsTableFilterColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ColorsTableFilterByBiggerOrSmallerValue = styled(ColorsTableFilter)`
  width: 100%;
  margin-bottom: 3%;
  
  & .select_filter {
    width: 50%;
  }
  
  & .input_filter {
    width: 50%;
    margin: 0 2%;
  }
  
  & .categories_filter {
    width: 98%;
  }
  
  & .MuiFormLabel-root.Mui-focused {
    color: #EB5757;
  }
`
import React from "react";
import '../login/login.css'
import {lpack} from "components/Language/language"

function CompleteRegistration() {
    return (
        <>
            <h2 className="copointerAuth_signIn-title fontForty">
                {lpack.accountCreated}
            </h2>
            <p className="copointerAuth_signIn-description">
                {lpack.checkYourInbox}
            </p>
            <div className="loader"/>
        </>
    )
}

export default CompleteRegistration
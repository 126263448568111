import React, {useEffect, useState} from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {lpack} from "../../components/Language/language";
import Cookies from 'js-cookie';
import axios from "axios";
import {urlPath} from "../../config/config";
import ManufacturiesTable from "../../components/ManufacturiesTable/ManufacturiesTable";
import Modal from "@material-ui/core/Modal";
import AddProduct from "../../components/AddProduct/AddProduct";
import {GoodsWrapper} from "../Goods/Goods.styled";

let hash

if (Cookies.get('user')) {
    hash = Cookies.get('user')
} else if (sessionStorage.getItem('user')) {
    hash = sessionStorage.getItem('user')
} else {
    sessionStorage.setItem('user', window.location.hash.slice(1, 33))
}

function Manufacturies() {
    const [manufacturiesData, setManufacturiesData] = useState([])
    const [modalStatus, setModalStatus] = useState(false)
    const [editProductsData, setEditProductsData] = useState(null)

    const getManufacturies = () => {
        axios.post(urlPath + 'manufactories', {}, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then((response) => {
            const responseData = response.data
            setManufacturiesData(responseData)

            if (responseData.status === 'NOAUTH') {
                // remove()
            }
        })
    }

    useEffect(() => {
        getManufacturies()
    }, [])

    const openModalHandler = data => {
        setModalStatus(true)
        setEditProductsData(data)
    }

    const closeModalHandler = () => {
        setModalStatus(false)
    }

    return (
        <GoodsWrapper>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="danger">
                            <h4>{lpack.manufacturies}</h4>
                        </CardHeader>
                        <CardBody>
                            <Modal
                                open={modalStatus}
                                style={{overflow: 'auto'}}
                                onClose={closeModalHandler}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <AddProduct
                                    image={false}
                                    getData={getManufacturies}
                                    data={manufacturiesData}
                                    editData={editProductsData}
                                    setModalStatus={setModalStatus}
                                />
                            </Modal>
                            <ManufacturiesTable
                                data={manufacturiesData}
                                getManufacturies={getManufacturies}
                                openModalHandler={openModalHandler}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </GoodsWrapper>
    );
}

export default Manufacturies
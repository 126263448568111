import React, {useEffect, useState} from "react";
import {AddProductWrapper, AddProductWrapperTitle} from "./AddProduct.styled";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Cookies from "js-cookie";
import {
    categoriesUrl,
    countriesUrl,
    galleryUrl,
    languages,
    manufactoriesUrl,
    pagesUrl,
    productsAdd
} from "../../config/config";
import UploadImage from "../UploadImage/UploadImage";

let hash

if (Cookies.get('user')) {
    hash = Cookies.get('user')
} else if (sessionStorage.getItem('user')) {
    hash = sessionStorage.getItem('user')
}

function AddProduct(props) {
    const {data, remoteData, getData, setModalStatus, editData, image} = props
    const [productData, setProductData] = useState([])
    const [imageInfo, setImageInfo] = useState({})
    const [selectOpenStatus, setSelectOpenStatus] = useState(null)
    const [selectedLanguage, setSelectedLanguage] = useState([])

    let parsedHeader = data.headers

    if (parsedHeader !== undefined) {
        parsedHeader = Object.values(parsedHeader).map(item => {
            return {
                title: item.sname.default,
                field: item.name
            }
        })
    }

    const addImageToProducts = (name, type, file) => {
        if ((editData !== null && productData.filter(e => e.table === 'gallery_id')[0].value !== 0)) {
            setImageInfo({
                id: productData.filter(e => e.table === 'gallery_id')[0].value,
                name: name,
                mimetype: type,
                content: file
            })
        } else {
            setImageInfo({
                name: name,
                mimetype: type,
                content: file
            })
        }
    }

    console.log(productData)

    const addProductHandler = async () => {
        const d = Object.keys(data.headers).map(key => {
            if (key !== 'id') {
                return {
                    [key]: productData.filter(e => e.table === key).length !== 0
                        ? productData.filter(e => e.table === key)[0].value
                            ? productData.filter(e => e.table === key)[0].value
                            : {
                                default: productData.filter(e => e.table === key)[0].default,
                                ru: productData.filter(e => e.table === key)[0].ru,
                            } : 0
                }
            }
        })
        await axios.post(
            window.location.pathname.includes('products')
                ? productsAdd
                : window.location.pathname.includes('categories')
                ? categoriesUrl
                : window.location.pathname.includes('countries')
                    ? countriesUrl
                    : window.location.pathname.includes('manufacturies')
                        ? manufactoriesUrl
                        : window.location.pathname.includes('pages')
                            ? pagesUrl
                            : ''
            , Object.assign({}, ...d), {
                headers: {
                    Authorization: "Bearer " + hash,
                },
            }).then(async res => {
            const responseData = res.data.info
            const imageData = {
                productId: responseData.insert_id,
                files: [imageInfo]
            }
            await axios.post(galleryUrl, imageData, {
                headers: {
                    Authorization: "Bearer " + hash,
                },
            })
        })
        getData()
        setModalStatus(false)
    }

    const editProductHandler = async () => {
        const d = Object.keys(data.headers).map(key => {
            if (!key.includes('gallery')) {
                if (window.location.pathname.includes('products')) {
                    return {
                        [key]: productData.filter(e => e.table === key).length !== 0
                            ? productData.filter(e => e.table === key)[0].default === undefined
                                ? productData.filter(e => e.table === key)[0].value
                                : {
                                    default: productData.filter(e => e.table === key)[0].default,
                                    ru: productData.filter(e => e.table === key)[0].ru,
                                }
                            : 0
                    }
                } else {
                    return {
                        [key]: productData.filter(e => e.table === key).length !== 0
                            ? productData.filter(e => e.table === key)[0].default === undefined
                                ? productData.filter(e => e.table === key)[0].value === undefined
                                    ? {
                                        default: data?.data?.filter(e => e.id === editData.id)[0]?.[key]?.default,
                                        ru: data?.data?.filter(e => e.id === editData.id)[0]?.[key]?.ru
                                    }
                                    : productData.filter(e => e.table === key)[0].value
                                : {
                                    default: productData.filter(e => e.table === key)[0].default,
                                    ru: productData.filter(e => e.table === key)[0].ru,
                                }
                            : ""
                    }
                }
            }
        })
        await axios.post(
            window.location.pathname.includes('products')
                ? productsAdd
                : window.location.pathname.includes('categories')
                ? categoriesUrl
                : window.location.pathname.includes('countries')
                    ? countriesUrl
                    : window.location.pathname.includes('manufacturies')
                        ? manufactoriesUrl
                        : window.location.pathname.includes('pages')
                            ? pagesUrl
                            : ''
            , Object.assign({}, ...d), {
                headers: {
                    Authorization: "Bearer " + hash,
                },
            }).then(async () => {
            if (imageInfo.content !== '') {
                const imageData = {
                    productId: productData.filter(e => e.table === 'id')[0].value,
                    files: [imageInfo]
                }
                await axios.post(galleryUrl, imageData, {
                    headers: {
                        Authorization: "Bearer " + hash,
                    },
                })
            }
        })
        getData()
        setModalStatus(false)
    }

    useEffect(() => {
        if (editData !== null) {
            const data = Object.entries(editData).map(item => {
                const headerData = remoteData?.filter(data => data.config.url.includes(item[0].slice(0, -3)))[0]
                const dataItem = Array.isArray(headerData?.data?.data)
                    ? headerData?.data?.data
                    : []

                if (item[0] !== 'tableData') {
                    if (typeof item[1] !== 'number' && (!item[0].includes('_id') || !item[0] === 'id')) {
                        return {
                            table: item[0],
                            ru: data?.data?.filter(el => el.id === editData['id'])[0]?.[item.field]?.ru,
                            default: data?.data?.filter(el => el.id === editData['id'])[0]?.[item.field]?.default
                        }
                    } else {
                        if (item[0].includes('_id')) {
                            return {
                                table: item[0],
                                value: item[1]
                                    ? dataItem.length !== 0 && dataItem
                                    .filter(e => {
                                        if (typeof e.name === 'object') {
                                            return e.name.default === item[1]
                                        } else {
                                            return e.name === item[1]
                                        }
                                    })[0]?.id
                                    : 0,
                            }
                        } else {
                            return {
                                table: item[0],
                                value: item[1] ? item[1] : 0,
                            }
                        }
                    }
                }
            })
            setProductData(data.filter(item => item !== undefined))
        }
    }, [])

    return (
        <AddProductWrapper>
            <AddProductWrapperTitle>
                {
                    editData !== null
                        ? 'Edit product'
                        : 'Add product'
                }
            </AddProductWrapperTitle>
            {
                parsedHeader !== undefined
                    ? editData !== null
                    ? Object.values(parsedHeader).map(item => {
                        if (item.field.includes('_id') && !item.field.includes('gallery')) {
                            const headerData = remoteData?.filter(data => data.config.url.includes(item.field.slice(0, -3)))[0]
                            const dataItem = Array.isArray(headerData?.data?.data)
                                ? headerData?.data?.data
                                : []

                            return (
                                <FormControl variant="outlined" className={'add_product-selector'}>
                                    <InputLabel
                                        id="demo-simple-select-outlined-label"
                                    >
                                        {editData[item.field] ? editData[item.field] : item.title}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        color="secondary"
                                        labelId="demo-simple-select-outlined-label"
                                        id="add_row-selector"
                                        open={selectOpenStatus?.field === item.field ? selectOpenStatus.value : false}
                                        onOpen={() => setSelectOpenStatus({field: item.field, value: true})}
                                        onClose={() => setSelectOpenStatus({field: item.field, value: false})}
                                        value={productData?.filter(e => e.table === item.field).map(e => e.value)}
                                        onChange={(e) => {
                                            setProductData(prevState => prevState
                                                .filter(el => el.table === item.field).length === 0
                                                ? [
                                                    ...prevState,
                                                    ...e.target.value
                                                        .filter(el => el !== prevState.filter(el => el.table === item.field)[0]?.value)
                                                        .map(e => {
                                                            return {
                                                                table: item.field,
                                                                value: e
                                                            }
                                                        })
                                                ]
                                                : [
                                                    ...prevState.filter(el => el.table !== item.field),
                                                    ...e.target.value
                                                        .filter(el => el !== 0)
                                                        .filter(el => el !== prevState.filter(el => el.table === item.field)[0]?.value)
                                                        .map(e => {
                                                            return {
                                                                table: item.field,
                                                                value: e
                                                            }
                                                        })
                                                ]
                                            )
                                            setSelectOpenStatus({field: item.field, value: false})
                                        }}
                                        label={editData[item.field] ? editData[item.field] : item.title}
                                    >
                                        {
                                            dataItem.length !== 0 && dataItem.map(data => {
                                                return (
                                                    <MenuItem
                                                        key={Math.random()}
                                                        value={data.id}
                                                    >
                                                        {
                                                            typeof data.name === 'object'
                                                                ? data.name.default
                                                                : data.name
                                                        }
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            )
                        } else {
                            if (item.field.includes('gallery')) {
                                // return (
                                //     <TextField
                                //         id="outlined-basic"
                                //         label={editData[item.field] ? editData[item.field] : item.title}
                                //         variant="outlined"
                                //         color="secondary"
                                //         fullWidth
                                //         value={imageInfo.name}
                                //         // onBlur={(e) => {
                                //         //     setImageInfo({
                                //         //         name: e.target.value,
                                //         //         mimetype: imageInfo.mimetype,
                                //         //         content: imageInfo.content
                                //         //     })
                                //         // }}
                                //     />
                                // )
                                return null
                            } else {
                                console.log(item.field)
                                return (
                                    <FormControl variant="outlined" className={'add_product-selector'}>
                                        {
                                            (!new RegExp(/^[0-9]*$/).test(editData[item.field]) && item.field !== 'id') && <>
                                                {/*<InputLabel*/}
                                                {/*    id="demo-simple-select-outlined-label"*/}
                                                {/*>*/}
                                                {/*    Language*/}
                                                {/*</InputLabel>*/}
                                                {/*<Select*/}
                                                {/*    multiple*/}
                                                {/*    color="secondary"*/}
                                                {/*    labelId="demo-simple-select-outlined-label"*/}
                                                {/*    id="add_row-selector"*/}
                                                {/*    open={selectOpenStatus?.field === item.field ? selectOpenStatus.value : false}*/}
                                                {/*    onOpen={() => setSelectOpenStatus({field: item.field, value: true})}*/}
                                                {/*    onClose={() => setSelectOpenStatus({*/}
                                                {/*        field: item.field,*/}
                                                {/*        value: false*/}
                                                {/*    })}*/}
                                                {/*    value={selectedLanguage?.filter(e => e.table === item.field)?.map(e => e.value)}*/}
                                                {/*    onChange={(e) => {*/}
                                                {/*        setSelectedLanguage(prevState => prevState*/}
                                                {/*            .filter(el => el.table === item.field).length === 0*/}
                                                {/*            ? [*/}
                                                {/*                ...prevState,*/}
                                                {/*                ...e.target.value*/}
                                                {/*                    .filter(el => el !== prevState.filter(el => el.table === item.field)[0]?.value)*/}
                                                {/*                    .map(e => {*/}
                                                {/*                        return {*/}
                                                {/*                            table: item.field,*/}
                                                {/*                            value: e*/}
                                                {/*                        }*/}
                                                {/*                    })*/}
                                                {/*            ]*/}
                                                {/*            : [*/}
                                                {/*                ...prevState.filter(el => el.table !== item.field),*/}
                                                {/*                ...e.target.value*/}
                                                {/*                    .filter(el => el !== 0)*/}
                                                {/*                    .filter(el => el !== prevState.filter(el => el.table === item.field)[0]?.value)*/}
                                                {/*                    .map(e => {*/}
                                                {/*                        return {*/}
                                                {/*                            table: item.field,*/}
                                                {/*                            value: e*/}
                                                {/*                        }*/}
                                                {/*                    })*/}
                                                {/*            ]*/}
                                                {/*        )*/}
                                                {/*        setSelectOpenStatus({field: item.field, value: false})*/}
                                                {/*    }}*/}
                                                {/*    label={'Language'}*/}
                                                {/*>*/}
                                                {/*    {*/}
                                                {/*        languages.length !== 0 && languages.map(data => {*/}
                                                {/*            return (*/}
                                                {/*                <MenuItem*/}
                                                {/*                    key={Math.random()}*/}
                                                {/*                    value={data.value}*/}
                                                {/*                >*/}
                                                {/*                    {data.title}*/}
                                                {/*                </MenuItem>*/}
                                                {/*            )*/}
                                                {/*        })*/}
                                                {/*    }*/}
                                                {/*</Select>*/}
                                            </>
                                        }
                                        {
                                            (item.field === 'name' || item.field === 'description')
                                                ? <>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={
                                                            editData[item.field] !== {}
                                                                ? 'UA ' + (
                                                                data?.data?.filter(el => el.id === editData['id'])[0]?.[item.field]?.default
                                                                    ? data?.data?.filter(el => el.id === editData['id'])[0]?.[item.field]?.default
                                                                    : ''
                                                            )
                                                                : 'UA ' + item.title
                                                        }
                                                        variant="outlined"
                                                        color="secondary"
                                                        fullWidth
                                                        onBlur={(e) => {
                                                            if (e.target.value) {
                                                                setProductData(prevState => prevState.filter(e => e.table === item.field).length === 0
                                                                    ? [
                                                                        ...prevState,
                                                                        new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                            ? {
                                                                                table: item.field,
                                                                                value: Number(e.target.value),
                                                                            }
                                                                            : {
                                                                                table: item.field,
                                                                                ru: productData?.filter(state => state.table === item.field)[0]?.ru
                                                                                    ? productData?.filter(state => state.table === item.field)[0]?.ru
                                                                                    : data?.data?.filter(el => el.id === editData['id'])[0]?.[item.field]?.ru,
                                                                                default: e.target.value,
                                                                            }
                                                                    ]
                                                                    : [
                                                                        ...prevState.filter(e => e.table !== item.field),
                                                                        new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                            ? {
                                                                                table: item.field,
                                                                                value: Number(e.target.value),
                                                                            }
                                                                            : {
                                                                                table: item.field,
                                                                                ru: productData?.filter(state => state.table === item.field)[0]?.ru
                                                                                    ? productData?.filter(state => state.table === item.field)[0]?.ru
                                                                                    : data?.data?.filter(el => el.id === editData['id'])[0]?.[item.field]?.ru,
                                                                                default: e.target.value,
                                                                            }
                                                                    ]
                                                                )
                                                            }
                                                        }}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={
                                                            editData[item.field] !== {}
                                                                ? 'RU ' + (
                                                                data?.data?.filter(el => el.id === editData['id'])[0]?.[item.field]?.ru
                                                                    ? data?.data?.filter(el => el.id === editData['id'])[0]?.[item.field]?.ru
                                                                    : ''
                                                            )
                                                                : 'RU ' + item.title
                                                        }
                                                        variant="outlined"
                                                        color="secondary"
                                                        fullWidth
                                                        onBlur={(e) => {
                                                            if (e.target.value) {
                                                                setProductData(prevState => prevState.filter(e => e.table === item.field).length === 0
                                                                    ? [
                                                                        ...prevState,
                                                                        new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                            ? {
                                                                                table: item.field,
                                                                                value: Number(e.target.value),
                                                                            }
                                                                            : {
                                                                                table: item.field,
                                                                                ru: e.target.value,
                                                                                default: productData?.filter(state => state.table === item.field)[0]?.default
                                                                                    ? productData?.filter(state => state.table === item.field)[0]?.default
                                                                                    : data?.data?.filter(el => el.id === editData['id'])[0]?.[item.field]?.default,
                                                                            }
                                                                    ]
                                                                    : [
                                                                        ...prevState.filter(e => e.table !== item.field),
                                                                        new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                            ? {
                                                                                table: item.field,
                                                                                value: Number(e.target.value),
                                                                            }
                                                                            : {
                                                                                table: item.field,
                                                                                ru: e.target.value,
                                                                                default: productData?.filter(state => state.table === item.field)[0]?.default
                                                                                    ? productData?.filter(state => state.table === item.field)[0]?.default
                                                                                    : data?.data?.filter(el => el.id === editData['id'])[0]?.[item.field]?.default,
                                                                            }
                                                                    ]
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </>
                                                : <TextField
                                                    id="outlined-basic"
                                                    label={item.title}
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth
                                                    onBlur={(e) => {
                                                        if (e.target.value) {
                                                            setProductData(prevState => prevState.filter(e => e.table === item.field).length === 0
                                                                ? [
                                                                    ...prevState,
                                                                    new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                        ? {
                                                                            table: item.field,
                                                                            value: Number(e.target.value),
                                                                        }
                                                                        : {
                                                                            table: item.field,
                                                                            ru: selectedLanguage
                                                                                .filter(e => e.table === item.field)[0]?.value === 'ru'
                                                                                ? e.target.value
                                                                                : productData?.filter(state => state.table === item.field)[0]?.ru,
                                                                            default: selectedLanguage
                                                                                .filter(e => e.table === item.field)[0]?.value === 'default'
                                                                                ? e.target.value
                                                                                : productData?.filter(state => state.table === item.field)[0]?.default,
                                                                        }
                                                                ]
                                                                : [
                                                                    ...prevState.filter(e => e.table !== item.field),
                                                                    new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                        ? {
                                                                            table: item.field,
                                                                            value: Number(e.target.value),
                                                                        }
                                                                        : {
                                                                            table: item.field,
                                                                            ru: selectedLanguage
                                                                                .filter(e => e.table === item.field)[0]?.value === 'ru'
                                                                                ? e.target.value
                                                                                : prevState?.filter(state => state.table === item.field)[0]?.ru,
                                                                            default: selectedLanguage
                                                                                .filter(e => e.table === item.field)[0]?.value === 'default'
                                                                                ? e.target.value
                                                                                : prevState?.filter(state => state.table === item.field)[0]?.default,
                                                                        }
                                                                ]
                                                            )
                                                        }
                                                    }}
                                                />
                                        }
                                        {/*<TextField*/}
                                        {/*    id="outlined-basic"*/}
                                        {/*    label={editData[item.field] ? editData[item.field] : item.title}*/}
                                        {/*    variant="outlined"*/}
                                        {/*    color="secondary"*/}
                                        {/*    fullWidth*/}
                                        {/*    onBlur={(e) => {*/}
                                        {/*        setProductData(prevState => e.target.value !== ''*/}
                                        {/*            ? [*/}
                                        {/*                ...prevState.filter(state => state.table !== item.field),*/}
                                        {/*                new RegExp(/^[0-9]*$/).test(e.target.value)*/}
                                        {/*                    ? {*/}
                                        {/*                        table: item.field,*/}
                                        {/*                        value: Number(e.target.value),*/}
                                        {/*                    }*/}
                                        {/*                    : {*/}
                                        {/*                        table: item.field,*/}
                                        {/*                        ru: selectedLanguage*/}
                                        {/*                            .filter(e => e.table === item.field)[0]?.value === 'ru'*/}
                                        {/*                            ? e.target.value*/}
                                        {/*                            : productData?.filter(state => state.table === item.field)[0]?.ru,*/}
                                        {/*                        ua: selectedLanguage*/}
                                        {/*                            .filter(e => e.table === item.field)[0]?.value === 'ua'*/}
                                        {/*                            ? e.target.value*/}
                                        {/*                            : productData?.filter(state => state.table === item.field)[0]?.ua,*/}
                                        {/*                        default: selectedLanguage*/}
                                        {/*                            .filter(e => e.table === item.field)[0]?.value === 'default'*/}
                                        {/*                            ? e.target.value*/}
                                        {/*                            : productData?.filter(state => state.table === item.field)[0]?.default,*/}
                                        {/*                    }*/}
                                        {/*            ]*/}
                                        {/*            : [*/}
                                        {/*                ...prevState,*/}
                                        {/*                new RegExp(/^[0-9]*$/).test(e.target.value)*/}
                                        {/*                    ? {*/}
                                        {/*                        table: item.field,*/}
                                        {/*                        value: Number(e.target.value),*/}
                                        {/*                    }*/}
                                        {/*                    : {*/}
                                        {/*                        table: item.field,*/}
                                        {/*                        ru: selectedLanguage*/}
                                        {/*                            .filter(e => e.table === item.field)[0]?.value === 'ru'*/}
                                        {/*                            ? e.target.value*/}
                                        {/*                            : productData?.filter(state => state.table === item.field)[0]?.ru,*/}
                                        {/*                        ua: selectedLanguage*/}
                                        {/*                            .filter(e => e.table === item.field)[0]?.value === 'ua'*/}
                                        {/*                            ? e.target.value*/}
                                        {/*                            : productData?.filter(state => state.table === item.field)[0]?.ua,*/}
                                        {/*                        default: selectedLanguage*/}
                                        {/*                            .filter(e => e.table === item.field)[0]?.value === 'default'*/}
                                        {/*                            ? e.target.value*/}
                                        {/*                            : productData?.filter(state => state.table === item.field)[0]?.default,*/}
                                        {/*                    }*/}
                                        {/*            ]*/}
                                        {/*        )*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                    </FormControl>
                                )
                            }
                        }
                    })
                    : Object.values(parsedHeader).map(item => {
                        if (item.field.includes('_id') && !item.field.includes('gallery')) {
                            const headerData = remoteData?.filter(data => data.config.url.includes(item.field.slice(0, -3)))[0]
                            const dataItem = Array.isArray(headerData?.data?.data)
                                ? headerData?.data?.data
                                : []
                            return (
                                <FormControl variant="outlined" className={'add_product-selector'}>
                                    <InputLabel
                                        id="demo-simple-select-outlined-label"
                                    >
                                        {item.title}
                                    </InputLabel>
                                    <Select
                                        multiple
                                        color="secondary"
                                        labelId="demo-simple-select-outlined-label"
                                        id="add_row-selector"
                                        open={selectOpenStatus?.field === item.field ? selectOpenStatus.value : false}
                                        onOpen={() => setSelectOpenStatus({field: item.field, value: true})}
                                        onClose={() => setSelectOpenStatus({field: item.field, value: false})}
                                        value={productData?.filter(e => e.table === item.field).map(e => e.value)}
                                        onChange={(e) => {
                                            setProductData(prevState => prevState
                                                .filter(el => el.table === item.field).length === 0
                                                ? [
                                                    ...prevState,
                                                    ...e.target.value.map(e => {
                                                        return {
                                                            table: item.field,
                                                            value: e
                                                        }
                                                    })
                                                ]
                                                : [
                                                    ...prevState.filter(el => el.table !== item.field),
                                                    ...e.target.value.map(e => {
                                                        return {
                                                            table: item.field,
                                                            value: e
                                                        }
                                                    })
                                                ]
                                            )
                                            setSelectOpenStatus({field: item.field, value: false})
                                        }}
                                        label={item.title}
                                    >
                                        {
                                            dataItem.length !== 0 && dataItem.map(data => {
                                                return (
                                                    <MenuItem
                                                        key={Math.random()}
                                                        value={data.id}
                                                    >
                                                        {
                                                            typeof data.name === 'object'
                                                                ? data.name.default
                                                                : data.name
                                                        }
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            )
                        } else {
                            if (item.field.includes('gallery')) {
                                // return (
                                //     <TextField
                                //         id="outlined-basic"
                                //         label={imageInfo.name ? '' : item.title}
                                //         variant="outlined"
                                //         color="secondary"
                                //         fullWidth
                                //         value={imageInfo.name}
                                //         // onBlur={(e) => {
                                //         //     setImageInfo({
                                //         //         name: e.target.value,
                                //         //         mimetype: imageInfo.mimetype,
                                //         //         content: imageInfo.content
                                //         //     })
                                //         // }}
                                //     />
                                // )
                                return null
                            } else {
                                return (
                                    <FormControl variant="outlined" className={'add_product-selector'}>
                                        {
                                            (item.field === 'name' || item.field === 'description') && <>
                                                {/*<InputLabel*/}
                                                {/*    id="demo-simple-select-outlined-label"*/}
                                                {/*>*/}
                                                {/*    Language*/}
                                                {/*</InputLabel>*/}
                                                {/*<Select*/}
                                                {/*    multiple*/}
                                                {/*    color="secondary"*/}
                                                {/*    labelId="demo-simple-select-outlined-label"*/}
                                                {/*    id="add_row-selector"*/}
                                                {/*    open={selectOpenStatus?.field === item.field ? selectOpenStatus.value : false}*/}
                                                {/*    onOpen={() => setSelectOpenStatus({field: item.field, value: true})}*/}
                                                {/*    onClose={() => setSelectOpenStatus({*/}
                                                {/*        field: item.field,*/}
                                                {/*        value: false*/}
                                                {/*    })}*/}
                                                {/*    value={selectedLanguage?.filter(e => e.table === item.field)?.map(e => e.value)}*/}
                                                {/*    onChange={(e) => {*/}
                                                {/*        setSelectedLanguage(prevState => prevState*/}
                                                {/*            .filter(el => el.table === item.field).length === 0*/}
                                                {/*            ? [*/}
                                                {/*                ...prevState,*/}
                                                {/*                ...e.target.value*/}
                                                {/*                    .filter(el => el !== prevState.filter(el => el.table === item.field)[0]?.value)*/}
                                                {/*                    .map(e => {*/}
                                                {/*                        return {*/}
                                                {/*                            table: item.field,*/}
                                                {/*                            value: e*/}
                                                {/*                        }*/}
                                                {/*                    })*/}
                                                {/*            ]*/}
                                                {/*            : [*/}
                                                {/*                ...prevState.filter(el => el.table !== item.field),*/}
                                                {/*                ...e.target.value*/}
                                                {/*                    .filter(el => el !== 0)*/}
                                                {/*                    .filter(el => el !== prevState.filter(el => el.table === item.field)[0]?.value)*/}
                                                {/*                    .map(e => {*/}
                                                {/*                        return {*/}
                                                {/*                            table: item.field,*/}
                                                {/*                            value: e*/}
                                                {/*                        }*/}
                                                {/*                    })*/}
                                                {/*            ]*/}
                                                {/*        )*/}
                                                {/*        setSelectOpenStatus({field: item.field, value: false})*/}
                                                {/*    }}*/}
                                                {/*    label={'Language'}*/}
                                                {/*>*/}
                                                {/*    {*/}
                                                {/*        languages.length !== 0 && languages.map(data => {*/}
                                                {/*            return (*/}
                                                {/*                <MenuItem*/}
                                                {/*                    key={Math.random()}*/}
                                                {/*                    value={data.value}*/}
                                                {/*                >*/}
                                                {/*                    {data.title}*/}
                                                {/*                </MenuItem>*/}
                                                {/*            )*/}
                                                {/*        })*/}
                                                {/*    }*/}
                                                {/*</Select>*/}
                                            </>
                                        }
                                        {
                                            (item.field === 'name' || item.field === 'description')
                                                ? <>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'UA ' + item.title}
                                                        variant="outlined"
                                                        color="secondary"
                                                        fullWidth
                                                        onBlur={(e) => {
                                                            if (e.target.value) {
                                                                setProductData(prevState => prevState.filter(e => e.table === item.field).length === 0
                                                                    ? [
                                                                        ...prevState,
                                                                        new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                            ? {
                                                                                table: item.field,
                                                                                value: Number(e.target.value),
                                                                            }
                                                                            : {
                                                                                table: item.field,
                                                                                ru: productData?.filter(state => state.table === item.field)[0]?.ru,
                                                                                default: e.target.value,
                                                                            }
                                                                    ]
                                                                    : [
                                                                        ...prevState.filter(e => e.table !== item.field),
                                                                        new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                            ? {
                                                                                table: item.field,
                                                                                value: Number(e.target.value),
                                                                            }
                                                                            : {
                                                                                table: item.field,
                                                                                ru: productData?.filter(state => state.table === item.field)[0]?.ru,
                                                                                default: e.target.value,
                                                                            }
                                                                    ]
                                                                )
                                                            }
                                                        }}
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={'RU ' + item.title}
                                                        variant="outlined"
                                                        color="secondary"
                                                        fullWidth
                                                        onBlur={(e) => {
                                                            if (e.target.value) {
                                                                setProductData(prevState => prevState.filter(e => e.table === item.field).length === 0
                                                                    ? [
                                                                        ...prevState,
                                                                        new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                            ? {
                                                                                table: item.field,
                                                                                value: Number(e.target.value),
                                                                            }
                                                                            : {
                                                                                table: item.field,
                                                                                ru: e.target.value,
                                                                                default: productData?.filter(state => state.table === item.field)[0]?.default,
                                                                            }
                                                                    ]
                                                                    : [
                                                                        ...prevState.filter(e => e.table !== item.field),
                                                                        new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                            ? {
                                                                                table: item.field,
                                                                                value: Number(e.target.value),
                                                                            }
                                                                            : {
                                                                                table: item.field,
                                                                                ru: e.target.value,
                                                                                default: productData?.filter(state => state.table === item.field)[0]?.default,
                                                                            }
                                                                    ]
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </>
                                                : <TextField
                                                    id="outlined-basic"
                                                    label={item.title}
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth
                                                    onBlur={(e) => {
                                                        if (e.target.value) {
                                                            setProductData(prevState => prevState.filter(e => e.table === item.field).length === 0
                                                                ? [
                                                                    ...prevState,
                                                                    new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                        ? {
                                                                            table: item.field,
                                                                            value: Number(e.target.value),
                                                                        }
                                                                        : {
                                                                            table: item.field,
                                                                            ru: selectedLanguage
                                                                                .filter(e => e.table === item.field)[0]?.value === 'ru'
                                                                                ? e.target.value
                                                                                : productData?.filter(state => state.table === item.field)[0]?.ru,
                                                                            default: selectedLanguage
                                                                                .filter(e => e.table === item.field)[0]?.value === 'default'
                                                                                ? e.target.value
                                                                                : productData?.filter(state => state.table === item.field)[0]?.default,
                                                                        }
                                                                ]
                                                                : [
                                                                    ...prevState.filter(e => e.table !== item.field),
                                                                    new RegExp(/^[0-9]*$/).test(e.target.value)
                                                                        ? {
                                                                            table: item.field,
                                                                            value: Number(e.target.value),
                                                                        }
                                                                        : {
                                                                            table: item.field,
                                                                            ru: selectedLanguage
                                                                                .filter(e => e.table === item.field)[0]?.value === 'ru'
                                                                                ? e.target.value
                                                                                : prevState?.filter(state => state.table === item.field)[0]?.ru,
                                                                            default: selectedLanguage
                                                                                .filter(e => e.table === item.field)[0]?.value === 'default'
                                                                                ? e.target.value
                                                                                : prevState?.filter(state => state.table === item.field)[0]?.default,
                                                                        }
                                                                ]
                                                            )
                                                        }
                                                    }}
                                                />
                                        }
                                    </FormControl>
                                )
                            }
                        }
                    })
                    : null
            }
            {
                image && <UploadImage
                    addImageToProducts={addImageToProducts}
                    editing={editData !== null}
                    editData={editData}
                    image={remoteData?.filter(data => data.config.url.includes('gallery'))[0]?.data?.data}
                />
            }
            <Button
                variant="outlined"
                color="secondary"
                onClick={
                    editData !== null
                        ? editProductHandler
                        : addProductHandler
                }
            >
                {editData !== null ? 'Edit product' : 'Add product'}
            </Button>
        </AddProductWrapper>
    )
}

export default AddProduct
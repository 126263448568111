import React, {Component} from "react";
import axios from "axios";
import MaterialTable from "material-table";
import {lpack} from "../Language/language";
import Cookies from 'js-cookie';
import {
    colorsDelete,
    colorsUrl,
    countriesDelete,
    countriesUrl,
    urlPath
} from "../../config/config";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";

let hash

if (Cookies.get('user')) {
    hash = Cookies.get('user')
} else if (sessionStorage.getItem('user')) {
    hash = sessionStorage.getItem('user')
}

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 1);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page === 1 ? page : page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            {/*<IconButton*/}
            {/*    onClick={handleFirstPageButtonClick}*/}
            {/*    // disabled={page === 1}*/}
            {/*    aria-label="first page"*/}
            {/*>*/}
            {/*    {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}*/}
            {/*</IconButton>*/}
            <IconButton
                onClick={handleBackButtonClick}
                // disabled={page === 1}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton>
                {page}-{count}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            {/*<IconButton*/}
            {/*    onClick={handleLastPageButtonClick}*/}
            {/*    // disabled={page >= Math.ceil(count / rowsPerPage) - 1}*/}
            {/*    aria-label="last page"*/}
            {/*>*/}
            {/*    {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}*/}
            {/*</IconButton>*/}
        </div>
    );
}

export default class CountriesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {title: lpack.staffId, field: 'id', editable: 'never'},
                {
                    title: lpack.staffNickName,
                    field: 'name',
                    validate: rowData => rowData.name === '' ? 'Name cannot be empty' : ''
                },
            ],
            data: [],
            isMounted: false,
            hash: hash,
            currentPage: 1
        }
    }

    addData = async (newData, url) => {
        const data = {
            name: {
                default: newData.name,
                en: newData.name
            },
            code: newData.code,
        }
        await axios.post(url, data, {
            headers: {
                Authorization: "Bearer " + this.state.hash,
            },
        })
        this.props.getColors()
    }

    updateData = async (newData, url) => {
        const data = {
            name: {
                default: newData.name,
                en: newData.name
            },
            code: newData.code,
            id: newData.id
        }
        await axios.post(url, data, {
            headers: {
                Authorization: "Bearer " + this.state.hash,
            },
        })
        this.props.getColors()
    }

    deleteData = async (oldData, url) => {
        const data = {}
        await axios.post(`${url}/${oldData.id}`, data, {
            headers: {
                Authorization: "Bearer " + this.state.hash,
            },
        })
        this.props.getColors()
    }

    handleChangePage = (page) => {
        const data = {
            pagination: {
                page: page,
                limit: 2
            }
        }
        axios.post(urlPath + 'colors', data, {
            headers: {
                Authorization: "Bearer " + hash,
            },
        }).then(response => {
            const data = JSON.parse(response.data)
            this.setState({
                data: data
            })

            if (data.status === 'NOAUTH') {
                // remove()
            }
        })
    }

    componentWillUnmount() {
        this.setState({
            isMounted: false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            const {data} = this.props

            let parsedHeader = data.headers
            if (parsedHeader !== undefined)
                parsedHeader = Object.values(parsedHeader).map(item => {
                    return {
                        title: item.sname.default,
                        field: item.name,
                        render: rowData => {
                            for (const [key, value] of Object.entries(rowData)) {
                                if (item.name === key) {
                                    if (typeof value === "object") {
                                        if (value.data === null) {
                                            return ""
                                        } else {
                                            if (typeof value === "object") {
                                                return value.default
                                            } else {
                                                return value
                                            }
                                        }
                                    } else {
                                        if (typeof value === "object") {
                                            return value.default
                                        } else {
                                            return value
                                        }
                                    }
                                }
                            }
                        },
                    }
                })

            this.setState({
                data: this.props.data,
                columns: parsedHeader
            })
        }
    }

    render() {
        const {columns, data, currentPage} = this.state
        const {remoteData} = this.props

        const filteredData = data.data && data.data.map(item => {
            const headers = Object.keys(item).map(header => {
                if (header.includes('_id')) {
                    const headerData = remoteData?.filter(data => data.config.url.includes(header.slice(0, -3)))[0]
                    const dataItem = {
                        [header]: typeof headerData?.data?.data?.name === 'string'
                            ? headerData?.data?.data?.name
                            : typeof headerData?.data?.data === 'undefined'
                                ? headerData?.data?.headers?.fileName?.sname?.default
                                : Array.isArray(headerData?.data?.data)
                                    ? typeof item[header]?.name === 'object'
                                        ? item[header]?.name?.default
                                        : item[header]?.name
                                    : headerData?.data?.data?.name?.default
                    }
                    return dataItem
                } else {
                    return {
                        [header]: typeof item[header] === 'object'
                            ? item[header]?.default
                            : item[header]
                    }
                }
            })

            return Object.assign({}, ...headers)
        })

        const rowsPerPage = data.info && data.info.input.pagination.limit

        return (
            <MaterialTable
                title={lpack.colors}
                columns={columns}
                data={filteredData}
                options={{
                    search: false,
                    sorting: false,
                    pageSizeOptions: false,
                    pageSize: typeof rowsPerPage === 'number' ? rowsPerPage : 25,
                }}
                editable={{
                    onRowAdd: newData => {
                        return this.addData(newData, colorsUrl)
                    },
                    onRowUpdate: newData => {
                        return this.updateData(newData, colorsUrl)
                    },
                    onRowDelete: oldData => {
                        return this.deleteData(oldData, colorsDelete)
                    }
                }}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            rowsPerPage={rowsPerPage}
                            count={Math.ceil(data.info && data.info.total / rowsPerPage)}
                            page={currentPage}
                            onChangePage={(e, page) => {
                                console.log(page)
                                this.setState({
                                    currentPage: page
                                })
                                this.handleChangePage(page)
                            }}
                            ActionsComponent={TablePaginationActions}
                        />
                    ),
                }}
            />
        );
    }
}